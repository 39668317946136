import React from 'react';
import useSlides from './useSlides';
import ShowSlides from './components/show-slides/ShowSlides';
import CreateSlide from './components/create-slide/CreateSlide';
import './Slides.less';

function Slides() {
  const { data, error, loading } = useSlides();

  if (error) return <h1>Something went wrong</h1>;
  if (loading) return <h1>Loading...</h1>;
  return (
    <div className="sliderBody">
      <h1>Շապիկներ</h1>
      {data.length ? <ShowSlides items={data} />
        : <CreateSlide />}
    </div>
  );
}

export default Slides;
