import React, { useEffect, useMemo, useState } from 'react';
import {
  Input,
  Row,
  Col,
  Button,
  Icon,
} from 'antd';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import headClasses from './header.module.less';
import DownloadButton from './components/download-button/DownloadButton';
import { search as storeSearch } from '../../store/search/action';
import { logout } from '../../store/admin/action';
import paths from '../../constants/index';


const { Search } = Input;


function HeaderTop({ search, searchAction, logoutAction, toggleSidebar, sidebarVisible }) {
  const { pathname } = useLocation();
  const showSearch = useMemo(() => (pathname !== `/${paths.DELIVERY_TIME}`
      && pathname !== `/${paths.SUPPORT}`
      && pathname !== `/${paths.MAIN_PAGE}`
      && pathname !== `/${paths.MAIN_SLIDES}`), [pathname]);
  const [value, setValue] = useState('');
  useEffect(() => setValue(search.searchWord), [search.searchWord]);
  const handleChange = ({ target }) => setValue(target.value);
  return (
    <div className={headClasses.HeadTop}>
      <Row>

        <Col lg={15} style={{ textAlign: 'right' }}>
          <DownloadButton pathname={pathname} />
        </Col>
        <Col lg={15}>
          {!sidebarVisible ? (
            <Button onClick={toggleSidebar}>
             ☰
            </Button> ) : (
            <Button onClick={toggleSidebar}>
             X
            </Button>
          )}
        </Col>
        <Col lg={7} className={`${headClasses.searchContainer} headerSearchContainer`}>
          {showSearch && (
          <Search
            placeholder="Search"
            onSearch={(value) => searchAction({ value, pathname: pathname.replace(/\//g, '') })}
            enterButton
            loading={search.loading}
            value={value}
            onChange={handleChange}
          />
          )}
        </Col>
        <Col lg={2}>
          <Button className={headClasses.btnElq} type="link" onClick={logoutAction}>
            Ելք
            <Icon type="caret-right" />
          </Button>
        </Col>
      </Row>
    </div>
  );
}

const mapStateToProps = ({ search }) => ({ search });

HeaderTop.propTypes = {
  search: PropTypes.objectOf(Object).isRequired,
  logoutAction: PropTypes.func.isRequired,
  searchAction: PropTypes.func.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
  sidebarVisible: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  searchAction: storeSearch,
  logoutAction: logout,
})(HeaderTop);
