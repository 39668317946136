
import {
  takeEvery, select, call, all, put,
} from 'redux-saga/effects';
import Axios from '../../utils/Axios';
import { getUsersOrders, getUsersOrdersError, getUsersOrdersSuccess } from './action';
import { changeTable } from '../tables/action';
import { selectList } from '../tables/reducer';
import pathname from '../../constants';

function getUserOrdersQuery({ params }) {
  return Axios.request({
    method: 'post',
    url: '/admin/orders',
    data: params,
  });
}

function* workGetUsersOrders({ payload: { id, status } }) {
  try {
    const { data } = yield call(getUserOrdersQuery, { params: { userId: id, status } });
    const { result: { rows } } = data;
    const currentList = [...yield select(selectList)];
    const item = currentList.find(({ id: userId }) => userId === id);
    item.userOrders = [...rows];
    yield put(changeTable({ name: pathname.CUSTOMERS, list: currentList }));
    yield put(getUsersOrdersSuccess({ id }));
  } catch (error) {
    yield put(getUsersOrdersError({ id }));
  }
}
function* watchGetUsersOrders() {
  yield takeEvery(getUsersOrders, workGetUsersOrders);
}

export default function* () {
  yield all([
    watchGetUsersOrders(),
  ]);
}
