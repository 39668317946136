import React from 'react';

const LogoSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="154" height="49" viewBox="0 0 154 49">
    <defs>
      <linearGradient id="a" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
        <stop offset="0" stopColor="#00c2ff" />
        <stop offset="1" stopColor="#004cff" />
      </linearGradient>
    </defs>
    <g transform="translate(-278 -55)">
      <path d="M13.258,39.553h-7.9L0,34.191H0V12.826H1.119a13.149,13.149,0,0,1,3.8-8.973,12.895,12.895,0,0,1,18.387,0A13.151,13.151,0,0,1,27.115,13H24.056a10.04,10.04,0,0,0-9.941-9.9,10.039,10.039,0,0,0-9.937,9.731H5.362V34.191h7.9v5.361h0ZM.977,14.976a1.536,1.536,0,1,0,1.536-1.535A1.538,1.538,0,0,0,.977,14.976Z" transform="translate(343.885 56.5)" fill="url(#a)" />
      <text transform="translate(278 95)" fontSize="37" fontFamily="SegoeUI-Bold, Segoe UI" fontWeight="700" letterSpacing="0.05em"><tspan x="0" y="0">MA</tspan></text>
      <text transform="translate(375 95)" fontSize="37" fontFamily="SegoeUI-Bold, Segoe UI" fontWeight="700" letterSpacing="0.05em"><tspan x="0" y="0">S</tspan></text>
      <text transform="translate(404 95)" fontSize="18" fontFamily="SegoeUI-Bold, Segoe UI" fontWeight="700" letterSpacing="0.05em"><tspan x="0" y="0">am</tspan></text>
      <path d="M7.9,26.726H0V21.365H7.9V0h5.362V21.365h0L7.9,26.727ZM8.965,2.15A1.536,1.536,0,1,0,10.5.615,1.538,1.538,0,0,0,8.965,2.15Z" transform="translate(359.245 69.326)" />
      <circle cx="2" cy="2" r="2" transform="translate(399 91)" fill="url(#a)" />
    </g>
  </svg>
);

export default LogoSvg;
