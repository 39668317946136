import React, {
  useEffect,
  useState,
  useCallback,
  memo,
} from 'react';
import { Input, InputNumber } from 'antd';
import PropTypes from 'prop-types';


const BlurChangeInputField = memo((props) => {
  const { onChange } = props;
  const { defaultValues, ...cloneProps } = { ...props };

  const [value, setValue] = useState(defaultValues);
  const handleLocalChange = useCallback(
    ({ target: { value: HTMLValue } }) => {
      setValue(HTMLValue);
    }, [setValue],
  );

  useEffect(() => {
    setValue(defaultValues);
  }, [defaultValues]);

  return (
    <Input
      {...cloneProps}
      value={value}
      onChange={handleLocalChange}
      onBlur={onChange}
      onPressEnter={onChange}
    />
  );
});

BlurChangeInputField.propTypes = {
  ...Input.propTypes,
  onChange: PropTypes.func.isRequired,
};


const BlurChangeInputFieldNumber = memo((props) => {
  const { onChange } = props;
  const { defaultValues, ...cloneProps } = { ...props };

  const [value, setValue] = useState(defaultValues);
  const handleLocalChange = useCallback(
    (newValue) => {
      setValue(newValue);
    }, [setValue],
  );

  useEffect(() => {
    setValue(defaultValues);
  }, [defaultValues]);
  return (
    <InputNumber
      {...cloneProps}
      value={value}
      onChange={handleLocalChange}
      onBlur={onChange}
      onPressEnter={onChange}
    />
  );
});

BlurChangeInputFieldNumber.propTypes = {
  ...Input.propTypes,
  onChange: PropTypes.func.isRequired,
};

export { BlurChangeInputFieldNumber, BlurChangeInputField };
