import { getAdministrators, getAdministratorsSuccess, getAdministratorsError } from '../../actions';

const getAdministratorsStatus = {
    getAdministratorsLoading: false,
    getAdministratorsSuccess: false,
    getAdministratorsError: null,
    getAdministratorsData: null,
};

const getAdministratorsActions = {
    [getAdministrators]: state => ({
        ...state,
        getAdministratorsStatus: {
            ...state.getAdministratorsStatus,
            getAdministratorsLoading: true,
        },
    }),
    [getAdministratorsSuccess]: (state, { payload }) => ({
        ...state,
        getAdministratorsStatus: {
            ...state.getAdministratorsStatus,
            getAdministratorsLoading: false,
            getAdministratorsSuccess: true,
            getAdministratorsData: payload,
        },
    }),
    [getAdministratorsError]: (state, { payload: error }) => ({
        ...state,
        getAdministratorsStatus: {
            ...state.getAdministratorsStatus,
            getAdministratorsLoading: false,
            getAdministratorsError: error,
        },
    }),
};

export { getAdministratorsStatus, getAdministratorsActions };
