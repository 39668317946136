import { handleActions, combineActions } from 'redux-actions';
import {
  getSlidesSuccess, getSlidesError, getSlides,
  createSlide, createSlideError, createSlideSuccess,
  deleteSlide, deleteSlideSuccess, deleteSlideError,
} from './action';

const initialState = {
  data: [],
  loading: false,
  error: false,
  confirmLoading: false,
  confirmError: false,
};

export default handleActions({
  [getSlides]: (state) => ({
    ...state,
    loading: true,
    error: false,
  }),
  [getSlidesError]: (state) => ({
    ...state,
    loading: false,
    error: true,
  }),
  [getSlidesSuccess]: (state, { payload: { data } }) => ({
    ...state,
    data,
    loading: false,
    error: false,
  }),
  [combineActions(createSlide, deleteSlide)]: (state) => ({
    ...state,
    confirmLoading: true,
    confirmError: false,
  }),
  [combineActions(createSlideSuccess, deleteSlideSuccess)]: (state) => ({
    ...state,
    confirmLoading: false,
    confirmError: false,
  }),
  [combineActions(createSlideError, deleteSlideError)]: (state) => ({
    ...state,
    confirmLoading: false,
    confirmError: true,
  }),
}, initialState);
