import React from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import PropTypes from 'prop-types';
import './CKEditor.less';

function CkEditor(props) {
  const { data } = props;
  return (
    <CKEditor
      editor={ClassicEditor}
      onInit={(editor) => {
        editor.setData(data || '');
      }}
      config={{
        toolbar: [
          'heading',
          'bold',
          'italic',
          'blockQuote',
          'bulletedList',
          'numberedList',
        ],

      }}
      {...props}
    />
  );
}

CkEditor.defaultProps = {
  data: '',
};

CkEditor.propTypes = {
  data: PropTypes.string,
};

export default CkEditor;
