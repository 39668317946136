import { Button } from 'antd';
import React from 'react';
import classes from './button.module.less';

function MyButton(props) {
  const { children, className } = props;
  return (
    <Button
      {...props}
      className={
            className
              ? `${className} ${classes.button}`
              : classes.button
      }
    >
      {children}
    </Button>
  );
}

MyButton.propTypes = {
  ...Button.propTypes,
};


export default MyButton;
