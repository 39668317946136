/* eslint-disable import/no-extraneous-dependencies */
import {
  all,
  takeEvery,
  put,
  delay,
  call,
} from 'redux-saga/effects';
import Axios from '../../utils/Axios';
import {
  getProfileFetch, getProfileFetchSuccess, loginSuccess, loginError, login, logout,
} from './action';
import { showMessage, cleanMessage } from '../message/action';
import { deleteCookie, getCookie, setCookie } from '../../utils/cookies';
import { C_TOKEN } from '../../constants';

function loginApiPost(authParams) {
  return Axios.request({
    method: 'post',
    url: '/user/signIn',
    data: authParams,
  });
}

function getTokenValidation({ token }) {
  return Axios.request({
    method: 'get',
    url: '/admin/profile/2',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

function* workGetProfileFetch() {
  try {
    const token = getCookie(C_TOKEN);
    if (!token) throw new Error();
    const { data: { success } } = yield call(getTokenValidation, { token });


    if (!success) throw new Error();

    yield put(loginSuccess({ token }));
  } catch (error) {
    // do some think
    deleteCookie(C_TOKEN);
  } finally {
    yield put(getProfileFetchSuccess());
  }
}

function* watchGetProfileFetch() {
  yield takeEvery(getProfileFetch, workGetProfileFetch);
}


function* workLogin({ payload: { email, password, rememberMe } }) {
  try {
    yield delay(2000);
    const { data: { token } } = yield call(
      loginApiPost, {
        userName: email, password, rememberMe,
      },
    );
    if (rememberMe) {
      setCookie(C_TOKEN, token, { 'max-age': 100 * 24 * 60 * 60 });
    } else {
      setCookie(C_TOKEN, token);
    }
    yield put(loginSuccess({ token }));
  } catch (error) {
    yield put(showMessage({
      text: 'Something is wrong',
      status: 'error',
      timer: 3.5,
    }));
    yield put(loginError());
  } finally {
    yield put(cleanMessage());
  }
}
function* watchLogin() {
  yield takeEvery(login, workLogin);
}


function* workLogout() {
  yield deleteCookie(C_TOKEN);
}

function* watchLogout() {
  yield takeEvery(logout, workLogout);
}
export default function* () {
  yield all([
    watchGetProfileFetch(),
    watchLogin(),
    watchLogout(),
  ]);
}
