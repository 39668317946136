import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import Layout from '../../layout';
import pathname from "../../constants";

function PrivateRoute({ children, isAuthenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) => (isAuthenticated ? (
        <Layout>
          {children}
        </Layout>
      ) : (
        <Redirect
          to={{
            pathname: `/${pathname.AUTHENTICATION}`,
            state: { from: location },
          }}
        />
      ))}
    />
  );
}

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
  isAuthenticated: PropTypes.bool,
};
PrivateRoute.defaultProps = {
  isAuthenticated: false,
};

export default PrivateRoute;
