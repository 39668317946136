import {
    all, put, call, takeEvery
} from 'redux-saga/effects';
import {
    createProduct, createProductSuccess, createProductError
} from './actions';
import Axios from '../../utils/Axios';

function* workCreateProduct({payload}) {
    try {
        const data = yield call(Axios.post,'/admin/product' ,{
            ...payload ,
        });
        yield put(createProductSuccess(data));
    } catch (err) {
        yield put(createProductError(err));
    }
}
function* watchCreateProduct() {
    yield takeEvery(createProduct, workCreateProduct);
}

export default function* () {
    yield all([
        watchCreateProduct(),
    ]);
}
