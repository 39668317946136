import { sendPermissions, sendPermissionsSuccess, sendPermissionsError } from '../../actions';

const sendPermissionsStatus = {
    sendPermissionsLoading: false,
    sendPermissionsSuccess: false,
    sendPermissionsError: null,
    sendPermissionsData: null,
};

const sendPermissionsActions = {
    [sendPermissions]: state => ({
        ...state,
        sendPermissionsStatus: {
            ...state.sendPermissionsStatus,
            sendPermissionsLoading: true,
        },
    }),
    [sendPermissionsSuccess]: (state, { payload }) => ({
        ...state,
        sendPermissionsStatus: {
            ...state.sendPermissionsStatus,
            sendPermissionsLoading: false,
            sendPermissionsSuccess: true,
            sendPermissionsData: payload,
        },
    }),
    [sendPermissionsError]: (state, { payload: error }) => ({
        ...state,
        sendPermissionsStatus: {
            ...state.sendPermissionsStatus,
            sendPermissionsLoading: false,
            sendPermissionsError: error,
        },
    }),
};

export { sendPermissionsStatus, sendPermissionsActions };
