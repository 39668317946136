import {
  all,
  takeEvery,
  call,
  put,
} from 'redux-saga/effects';
import { searchSelect, searchSelectSuccess } from './action';
import { getRequests } from './handleFunctions';


function* workSearchList({ payload: { name, value } }) {
  try {
    const { data: { result: { rows = [] } } } = yield call(
      getRequests, { name, data: { searchWord: value } },
    );
    const list = rows;
    yield put(searchSelectSuccess({ list, name }));
  } catch (error) {
  }
}

function* watchSearchList() {
  yield takeEvery(searchSelect, workSearchList);
}


export default function* () {
  yield all([
    watchSearchList(),
  ]);
}
