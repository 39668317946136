import { combineActions, handleActions } from 'redux-actions';
import {
  createPromoCodes, createPromoCodesSuccess, createPromoCodesError,
  editPromoCode, editPromoCodeSuccess, editPromoCodeError,
  downloadPromosRequested, downloadPromosError, downloadPromosSuccess,
} from './action';

const initialState = {
  status: {
    error: false,
    loading: false,
  },
  downloadStatus: {
    inProcess: false,
    error: false,
  },
};

const statuses = {
  loading: {
    loading: true,
    error: false,
  },
  error: {
    loading: false,
    error: true,
  },
  success: {
    loading: false,
    error: false,
  },
};

const setStatus = (status) => (state) => ({
  ...state,
  status: {
    ...statuses[status],
  },
});

export default handleActions({
  [combineActions(createPromoCodes, editPromoCode)]: setStatus('loading'),
  [combineActions(createPromoCodesSuccess, editPromoCodeSuccess)]: setStatus('success'),
  [combineActions(createPromoCodesError, editPromoCodeError)]: setStatus('error'),

  [downloadPromosRequested]: (state) => ({
    ...state,
    downloadStatus: {
      inProcess: true,
      error: false,
    },
  }),
  [downloadPromosSuccess]: (state) => ({
    ...state,
    downloadStatus: {
      inProcess: false,
      error: false,
    },
  }),
  [downloadPromosError]: (state) => ({
    ...state,
    downloadStatus: {
      inProcess: false,
      error: true,
    },
  }),
}, initialState);

export const selectDownloadStatus = ({ promoCodes }) => promoCodes.downloadStatus;
