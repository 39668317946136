import {
  takeEvery, call, all, put,
} from 'redux-saga/effects';
import { message } from 'antd';
import Axios from '../../utils/Axios';
import { getTableData } from '../tables/action';
import {
  acceptOrder, deleteOrder, requestSuccess, requestError,
} from './action';
import pathname from '../../constants';

const acceptOrderApi = (id) => Axios.request({
  method: 'PUT',
  url: `/admin/orders/${id}/accept`,
});

function* workAcceptOrder({ payload: { orderId } }) {
  try {
    yield call(acceptOrderApi, orderId);
    yield put(requestSuccess());
    yield put(getTableData({ name: pathname.ORDER }));
    yield message.success('Accepted');
  } catch (e) {
    yield put(requestError());
    yield message.error(e.response.data.message);
  }
}
function* watchAcceptOrder() {
  yield takeEvery(acceptOrder, workAcceptOrder);
}


const deleteOrderApi = (id) => Axios.request({
  method: 'DELETE',
  url: `/admin/orders/${id}/delete`,
});

function* workDeleteOrder({ payload: { orderId } }) {
  try {
    yield call(deleteOrderApi, orderId);
    yield put(requestSuccess());
    yield put(getTableData({ name: pathname.ORDER }));
    yield message.success('Order successfully deleted');
  } catch (e) {
    yield put(requestError());
    yield message.error(e.response.data.message);
  }
}
function* watchDeleteOrder() {
  yield takeEvery(deleteOrder, workDeleteOrder);
}

export default function* () {
  yield all([
    watchAcceptOrder(),
    watchDeleteOrder(),
  ]);
}
