import {
  takeEvery, call, put, all,
} from 'redux-saga/effects';
import { message } from 'antd';
import {
  getSlides, getSlidesError, getSlidesSuccess, createSlide, createSlideError, createSlideSuccess,
  deleteSlide, deleteSlideSuccess, deleteSlideError,
} from './action';
import {
  getSlidesRequest, createSlideRequest, deleteSlideRequest,
} from './requestFunctions';
import { getDataForCreate } from './handleFunctions';

function* workGetSlides() {
  try {
    const { data: { result: { rows } } } = yield call(getSlidesRequest);
    const data = rows.sort((a, b) => a.id - b.id);
    yield put(getSlidesSuccess({ data }));
  } catch (e) {
    yield put(getSlidesError());
  }
}

function* watchGetSlides() {
  yield takeEvery(getSlides, workGetSlides);
}


function* workCreateSlide({ payload: { values } }) {
  const data = getDataForCreate(values);
  try {
    yield call(createSlideRequest, { data });
    message.success('Հաջողությամբ վերբեռնվել է');
    yield put(getSlides());
    yield put(createSlideSuccess());
  } catch (e) {
    message.error('Upload failed');
    yield put(createSlideError());
  }
}
function* watchCreateSlide() {
  yield takeEvery(createSlide, workCreateSlide);
}

function* workDeleteSlide({ payload: { id } }) {
  try {
    yield call(deleteSlideRequest, { id });
    yield put(getSlides());
    yield put(deleteSlideSuccess());
  } catch (e) {
    yield put(deleteSlideError());
  }
}
function* watchDeleteSlide() {
  yield takeEvery(deleteSlide, workDeleteSlide);
}


export default function* () {
  yield all([watchGetSlides(), watchCreateSlide(), watchDeleteSlide()]);
}
