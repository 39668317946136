import React, { Suspense, lazy, useEffect } from 'react';
import './App.css';
import { message as antdMessage } from 'antd';
import { BrowserRouter as Router,Switch, Redirect, Route } from 'react-router-dom';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { PrivateRoute, AuthorizationRoute } from './components';
import { getProfileFetch } from './store/admin/action';
import pathname from './constants';
import NoMatch from "./pages/no-match/NoMatch";
import Slides from "./pages/slides/Slides";


const LoginForm = lazy(() => import('./pages/authorisation/LoginForm'));
const Order =  lazy(() => import('./pages/order/Order'));
const Product = lazy(() => import('./pages/product/Product'));
const Type = lazy(() => import('./pages/type/Type'));
const Filter = lazy(() => import('./pages/filter/Filter'));
const Brand = lazy(() => import('./pages/brand/Brand'));
const Usage = lazy(() => import('./pages/usage/Usage'));
const PromoCode= lazy(() => import('./pages/promoCode/PromoCode'));
const Clients= lazy(() => import('./pages/clients/Clients'));
const Followers= lazy(() => import('./pages/followers/Followers'));
const MainPage = lazy(() => import('./pages/mainPage/MainPage'));
const Job= lazy(() => import('./pages/job/Job'));
const Specifications = lazy(() => import('./pages/specifications/Specifications'));
const DeliveryTime = lazy(() => import('./pages/delivery-time/DeliveryTime'));
const Administrator = lazy(() => import('./pages/admin/Administrator'));

function App({
  isAuthenticated,
  getProfileFetchAction,
  message,
  initialFetching,
}) {
  useEffect(() => {
    getProfileFetchAction();
  }, [getProfileFetchAction]);
  useEffect(() => {
    if (message.status) {
      const {
        status,
        timer,
        text,
        key,
      } = message;
      antdMessage[status]({ content: text, key, duration: timer });
    }
  }, [message]);
  if (!initialFetching) return 'loading';
  return (
    <Router>
      <div className="App">

          <Suspense fallback={<div>loading...</div>}>
             <Switch>
                <PrivateRoute path={"/"} exact isAuthenticated={isAuthenticated}>
                  <Redirect
                    to={`/${pathname.ORDER}`}
                  />
                </PrivateRoute>
                <PrivateRoute path={`/${pathname.ORDER}`} exact isAuthenticated={isAuthenticated}>
                    <Order />
                </PrivateRoute>
                <PrivateRoute path={`/${pathname.PRODUCTS}`} exact isAuthenticated={isAuthenticated}>
                    <Product />
                </PrivateRoute>
                <PrivateRoute path={`/${pathname.TYPE}`} exact isAuthenticated={isAuthenticated}>
                    <Type />
                </PrivateRoute>
                <PrivateRoute path={`/${pathname.FILTER}`} exact isAuthenticated={isAuthenticated}>
                    <Filter />
                </PrivateRoute>
                <PrivateRoute path={`/${pathname.BRAND}`} exact isAuthenticated={isAuthenticated}>
                    <Brand />
                </PrivateRoute>
                <PrivateRoute path={`/${pathname.USAGE}`} exact isAuthenticated={isAuthenticated}>
                    <Usage />
                </PrivateRoute>
                <PrivateRoute path={`/${pathname.PROMO_CODE}`} exact isAuthenticated={isAuthenticated}>
                    <PromoCode />
                </PrivateRoute>
                <PrivateRoute path={`/${pathname.CUSTOMERS}`} exact isAuthenticated={isAuthenticated}>
                    <Clients />
                </PrivateRoute>
                <PrivateRoute path={`/${pathname.FOLLOWERS}`} exact isAuthenticated={isAuthenticated}>
                    <Followers />
                </PrivateRoute>
                <PrivateRoute path={`/${pathname.MAIN_PAGE}`} exact isAuthenticated={isAuthenticated}>
                    <MainPage />
                </PrivateRoute>
                <PrivateRoute path={`/${pathname.JOB}`} exact isAuthenticated={isAuthenticated}>
                    <Job />
                </PrivateRoute>
                <PrivateRoute path={`/${pathname.DELIVERY_TIME}`} exact isAuthenticated={isAuthenticated}>
                  <DeliveryTime />
                </PrivateRoute>
               <PrivateRoute path={`/${pathname.MAIN_SLIDES}`} exact isAuthenticated={isAuthenticated}>
                 <Slides />
               </PrivateRoute>
                 <PrivateRoute path={`/${pathname.ADMINISTRATORS}`} exact isAuthenticated={isAuthenticated}>
                     <Administrator />
                 </PrivateRoute>
                <PrivateRoute path={`/${pathname.SUPPORT}`} exact isAuthenticated={isAuthenticated}>
                    <Specifications />
                </PrivateRoute>
                <AuthorizationRoute path={`/${pathname.AUTHENTICATION}`} exact isAuthenticated={isAuthenticated}>
                  <LoginForm />
                </AuthorizationRoute>
                <Route component={NoMatch} />
            </Switch>
          </Suspense>

      </div>
    </Router>
  );
}

const mapStateToProps = ({
  admin: { isAuthenticated, initialFetching },
  message,
}) => ({
  isAuthenticated,
  message,
  initialFetching,
});


App.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  getProfileFetchAction: PropTypes.func.isRequired,
  message: PropTypes.objectOf(Object).isRequired,
  initialFetching: PropTypes.bool.isRequired,
};
export default connect(mapStateToProps, {
  getProfileFetchAction: getProfileFetch,
})(App);
