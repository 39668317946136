import Axios from '../../utils/Axios';


function getRequests({ name, data }) {
  const APIProps = {
    data,
  };
  switch (name) {
    case 'types':
      APIProps.method = 'post';
      APIProps.url = '/product/type';
      break;
    case 'usages':
      APIProps.method = 'post';
      APIProps.url = '/product/usage';
      break;
    case 'brand':
      APIProps.method = 'post';
      APIProps.url = '/product/brand';
      break;
    case 'batteries':
      APIProps.method = 'post';
      APIProps.url = '/admin/products';
      APIProps.data.type = 'battery';
      break;
    case 'paperBags':
      APIProps.method = 'post';
      APIProps.url = '/admin/products';
      APIProps.data.type = 'bug';
      break;
    case 'extraFilter':
      APIProps.method = 'post';
      APIProps.url = '/product/extraFilter';
      break;

    default:
      break;
  }
  return Axios.request(APIProps);
}


export { getRequests };
