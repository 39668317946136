import React from 'react';

const TrashSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17.23" viewBox="0 0 16 17.23">
    <g id="trash" transform="translate(-17.066)">
      <g id="Group_6453" data-name="Group 6453" transform="translate(17.066)">
        <g id="Group_6452" data-name="Group 6452" transform="translate(0)">
          <path id="Path_6088" data-name="Path 6088" d="M32.45,2.462H28.143V1.846A1.846,1.846,0,0,0,26.3,0H23.835a1.846,1.846,0,0,0-1.846,1.846v.615H17.681a.615.615,0,1,0,0,1.231h.669l1.177,12.979a.615.615,0,0,0,.615.559h9.846a.615.615,0,0,0,.615-.559L31.781,3.692h.669a.615.615,0,1,0,0-1.231ZM23.22,1.846a.615.615,0,0,1,.615-.615H26.3a.615.615,0,0,1,.615.615v.615H23.22V1.846ZM29.427,16H20.7L19.589,3.692H30.546Z" transform="translate(-17.066)" fill="#43425d" />
        </g>
      </g>
      <g id="Group_6455" data-name="Group 6455" transform="translate(21.372 4.921)">
        <g id="Group_6454" data-name="Group 6454" transform="translate(0)">
          <path id="Path_6089" data-name="Path 6089" d="M138.337,145.681v0l-.615-8.615a.617.617,0,1,0-1.231.087l.615,8.615a.615.615,0,0,0,.615.572h.044A.615.615,0,0,0,138.337,145.681Z" transform="translate(-136.489 -136.49)" fill="#43425d" />
        </g>
      </g>
      <g id="Group_6457" data-name="Group 6457" transform="translate(24.45 4.923)">
        <g id="Group_6456" data-name="Group 6456" transform="translate(0)">
          <path id="Path_6090" data-name="Path 6090" d="M222.481,136.533a.615.615,0,0,0-.615.615v8.615a.615.615,0,0,0,1.231,0v-8.615A.615.615,0,0,0,222.481,136.533Z" transform="translate(-221.866 -136.533)" fill="#43425d" />
        </g>
      </g>
      <g id="Group_6459" data-name="Group 6459" transform="translate(26.91 4.921)">
        <g id="Group_6458" data-name="Group 6458" transform="translate(0)">
          <path id="Path_6091" data-name="Path 6091" d="M291.362,136.491a.617.617,0,0,0-.659.572l-.615,8.615a.615.615,0,0,0,.569.659h.047a.615.615,0,0,0,.615-.572l.615-8.615A.617.617,0,0,0,291.362,136.491Z" transform="translate(-290.086 -136.489)" fill="#43425d" />
        </g>
      </g>
    </g>
  </svg>

);

export default TrashSvg;
