import { deleteAdministrator, deleteAdministratorSuccess, deleteAdministratorError } from '../../actions';

const deleteAdministratorStatus = {
    deleteAdministratorLoading: false,
    deleteAdministratorSuccess: false,
    deleteAdministratorError: null,
    deleteAdministratorData: null,
};

const deleteAdministratorActions = {
    [deleteAdministrator]: state => ({
        ...state,
        deleteAdministratorStatus: {
            ...state.deleteAdministratorStatus,
            deleteAdministratorLoading: true,
        },
    }),
    [deleteAdministratorSuccess]: (state, { payload }) => ({
        ...state,
        deleteAdministratorStatus: {
            ...state.deleteAdministratorStatus,
            deleteAdministratorLoading: false,
            deleteAdministratorSuccess: true,
            deleteAdministratorData: payload,
        },
    }),
    [deleteAdministratorError]: (state, { payload: error }) => ({
        ...state,
        deleteAdministratorStatus: {
            ...state.deleteAdministratorStatus,
            deleteAdministratorLoading: false,
            deleteAdministratorError: error,
        },
    }),
};

export { deleteAdministratorStatus, deleteAdministratorActions };
