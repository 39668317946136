import {
    all, put, call, takeEvery
} from 'redux-saga/effects';
import {
    sendPermissions,
    sendPermissionsSuccess,
    sendPermissionsError,
    getAdministrators,
    getAdministratorsSuccess,
    getAdministratorsError,
    deleteAdministrator,
    deleteAdministratorSuccess,
    deleteAdministratorError,
    getAdministratorById,
    getAdministratorByIdSuccess,
    getAdministratorByIdError
} from './actions';
import Axios from '../../utils/Axios';

function* workSendPermissions({payload}) {
    const permissions = [];
    Object.keys(payload).map(function(key, index) {
        switch(key) {
            case 'all':
                permissions.push(0);
                break;
            case 'brand':
                permissions.push(5);
                break;
            case 'order':
                permissions.push(1);
                break;
            case 'product':
                permissions.push(2);
                break;
            case 'type':
                permissions.push(3);
                break;
            case 'filter':
                permissions.push(4);
                break;
            case 'usage':
                permissions.push(6);
                break;
            case 'promoCode':
                permissions.push(7);
                break;
            case 'customer':
                permissions.push(8);
                break;
            case 'subscribers':
                permissions.push(9);
                break;
            case 'mainSlider':
                permissions.push(12);
                break;
            case 'slider':
                permissions.push(10);
                break;
            case 'deliveryTime':
                permissions.push(11);
                break;
        }
    });
    try {
        const data = yield call(Axios.post,'/admin/worker' ,{
            ...payload ,
            permissions
        });
        yield put(sendPermissionsSuccess(data));
    } catch (err) {
        yield put(sendPermissionsError(err));
    }
}
function* watchSendPermissions() {
    yield takeEvery(sendPermissions, workSendPermissions);
}

function* workGetAdministrators() {
    try {
        const data = yield call(Axios.get, '/admin/worker');
        yield put(getAdministratorsSuccess(data));
    } catch (err) {
        yield put(getAdministratorsError(err));
    }
}
function* watchGetAdministrators() {
    yield takeEvery(getAdministrators, workGetAdministrators);
}

function* workDeleteAdministrators({ payload }) {
    try {
        const data = yield call(Axios.delete, `/admin/worker/${payload}`);
        yield put(deleteAdministratorSuccess(data));
    } catch (err) {
        yield put(deleteAdministratorError(err));
    }
}

function* watchDeleteAdministrator() {
    yield takeEvery(deleteAdministrator, workDeleteAdministrators);
}

function* workGetAdministratorById({ payload }) {
    try {
        const data = yield call(Axios.get, `/admin/worker/${payload}`);
        yield put(getAdministratorByIdSuccess(data));
    } catch (err) {
        yield put(getAdministratorByIdError(err));
    }
}

function* watchGetAdministratorById() {
    yield takeEvery(getAdministratorById, workGetAdministratorById);
}

export default function* () {
    yield all([
        watchSendPermissions(),
        watchGetAdministrators(),
        watchDeleteAdministrator(),
        watchGetAdministratorById()
    ]);
}
