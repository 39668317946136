import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'antd';

function PromoRadioGroup({ value, setValue }) {
  const onChange = (e) => {
    setValue(e.target.value);
  };
  return (
    <Radio.Group onChange={onChange} defaultValue={value}>
      <Radio value>Օգտագործված</Radio>
      <Radio value={false}>Չօգտագործված</Radio>
      <Radio value="">Բոլորը</Radio>
    </Radio.Group>
  );
}

PromoRadioGroup.propTypes = {
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  setValue: PropTypes.func.isRequired,
};

export default memo(PromoRadioGroup);
