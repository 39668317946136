import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { deleteSlide } from '../../../../store/slides/action';
import { selectConfirmLoading, selectConfirmError } from '../../../../store/slides/selectors';

function ConfirmDelete({ item, onCancel }) {
  const loading = useSelector(selectConfirmLoading);
  const error = useSelector(selectConfirmError);

  const dispatch = useDispatch();
  const handleDelete = useCallback(() => {
    dispatch(deleteSlide({ id: item.id }));
  }, [dispatch, item]);

  return (
    <div className="modalParentDeleteSlider">
      <h1>Վստա՞հ եք, որ ցանկցանցում եք ջնջել այս սլայդը</h1>
      {error && <h3>Something went wrong</h3>}
      <div className="flexItem">
        <Form.Item>
          <Button type="primary" onClick={onCancel}>ՉԵղարկել</Button>
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={handleDelete} loading={loading}>Ջնջել</Button>
        </Form.Item>
      </div>
    </div>
  );
}

ConfirmDelete.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ConfirmDelete;
