import { createActions } from 'redux-actions';

export const {
  saveDragResult,
  saveDragResultSuccess,
  saveDragResultError,
  saveDragResultReset,
  toggleSectionVisibility,
  toggleSectionVisibilitySuccess,
  toggleSectionVisibilityError,
  toggleSectionVisibilityReset,
} = createActions(
  'SAVE_DRAG_RESULT',
  'SAVE_DRAG_RESULT_SUCCESS',
  'SAVE_DRAG_RESULT_ERROR',
  'SAVE_DRAG_RESULT_RESET',
  'TOGGLE_SECTION_VISIBILITY',
  'TOGGLE_SECTION_VISIBILITY_SUCCESS',
  'TOGGLE_SECTION_VISIBILITY_ERROR',
  'TOGGLE_SECTION_VISIBILITY_RESET',
);
