import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import Modal from 'antd/lib/modal';
import SlideItem from '../slide-item/SlideItem';
import CreateSlides from '../create-slide/CreateSlide';
import { MAX_SLIDES } from '../../constants';
import useShowSlides from './useShowSlides';
import ConfirmDelete from '../confirm-delete/ConfirmDelete';
import './ShowSlides.less';

function ShowSlides({ items }) {
  const {
    showCreate, showDelete, modalItem,
    toggleCreate, setDelete, cancelChange,
  } = useShowSlides();


  return (
    <div className="slides-container">
      <Modal
        className="deleteSliderModal"
        visible={showDelete}
        destroyOnClose
        footer={null}
        onCancel={cancelChange}
      >
        {showDelete && <ConfirmDelete item={modalItem} onCancel={cancelChange} />}
      </Modal>
      <div className="items-container">
        {items.map((item) => (
          <SlideItem item={item} key={item.id} setDelete={setDelete} />
        ))}
      </div>
      {items.length < MAX_SLIDES && (
      <div>
        <Button className="addSlideMore" onClick={toggleCreate}>{showCreate ? 'Չեղարկել' : 'Ավելացնել շապիկ'}</Button>
        {showCreate && <CreateSlides />}
      </div>
      )}
    </div>
  );
}

ShowSlides.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
};

ShowSlides.defaultProps = {
  items: [],
};


export default ShowSlides;
