export const getDataForCreate = (values) => {
  const {
    image, link, video,
  } = values;
  const formData = new FormData();
  if (image.length) {
    formData.append('image', image[0].originFileObj);
  } else formData.append('video', video[0]);
  if (link) {
    formData.append('link', link);
  }
  return formData;
};
