import {
  takeEvery, call, all, put,
} from 'redux-saga/effects';
import Axios from '../../utils/Axios';
import { handleGetData, handleSendData } from './handleFunctions';

import {
  getWeekTime, getWeekTimeSuccess, getWeekTimeError,
  postWeekTime, postWeekTimeSuccess, postWeekTimeError,
} from './action';

const getWeekTimeApi = () => Axios.request({
  method: 'GET',
  url: '/user/deliveryWeekTime',
});

function* workGetWeekTime() {
  try {
    const { data: { result } } = yield call(getWeekTimeApi);
    const data = handleGetData(result);
    yield put(getWeekTimeSuccess({ data }));
  } catch (e) {
    yield put(getWeekTimeError());
  }
}

function* watchGetWeekTime() {
  yield takeEvery(getWeekTime, workGetWeekTime);
}


const postWeekTimeApi = (data) => Axios.request({
  method: 'POST',
  url: '/admin/addDeliveryWeekTime',
  data,
});

function* workPostWeekTime({ payload: { data } }) {
  try {
    const finalData = handleSendData(data);
    yield call(postWeekTimeApi, finalData);
    yield put(postWeekTimeSuccess());
  } catch (e) {
    yield put(postWeekTimeError());
  }
}

function* watchPostWeekTime() {
  yield takeEvery(postWeekTime, workPostWeekTime);
}


export default function* () {
  yield all([
    watchGetWeekTime(),
    watchPostWeekTime(),
  ]);
}
