import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import pathname from '../../constants';

function AuthorizationRoute({ children, isAuthenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) => (!isAuthenticated ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: `/${pathname.ORDER}`,
            state: { from: location },
          }}
        />
      ))}
    />
  );
}

AuthorizationRoute.propTypes = {
  children: PropTypes.node.isRequired,
  isAuthenticated: PropTypes.bool,
};
AuthorizationRoute.defaultProps = {
  isAuthenticated: false,
};

export default AuthorizationRoute;
