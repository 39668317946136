import pathname from '../../constants';

const URLGeneratorPost = {
  [pathname.CUSTOMERS]: {
    method: 'post',
    url: '/admin/profile/all',
  },
  [pathname.FOLLOWERS]: {
    method: 'post',
    url: '/admin/subscribers',
  },
  [pathname.PROMO_CODE]: {
    method: 'post',
    url: '/admin/promoCode',
  },
  [pathname.PRODUCTS]: {
    method: 'post',
    url: '/admin/products',
  },
  [pathname.TYPE]: {
    method: 'post',
    url: '/product/type',
  },
  [pathname.MAIN_PAGE]: {
    method: 'get',
    url: '/admin/slider',
  },
  [pathname.USAGE]: {
    method: 'post',
    url: '/product/usage',
  },
  [pathname.BRAND]: {
    method: 'post',
    url: '/product/brand',
  },
  [pathname.ORDER]: {
    method: 'post',
    url: '/admin/orders',
  },
  [pathname.FILTER]: {
    method: 'post',
    url: '/product/extraFilter',
  },
  [pathname.JOB]: {
    method: 'post',
    url: '/vocation',
  },
};
const URLGeneratorCreate = {
  [pathname.TYPE]: {
    url: '/admin/type/',
  },
  [pathname.USAGE]: {
    url: '/admin/usage/',
    headers: { 'Content-Type': 'multipart/form-data' },
  },
  [pathname.BRAND]: {
    url: '/admin/brand/',
  },
  [pathname.FILTER]: {
    url: '/admin/extraFilter/',
  },
  [pathname.JOB]: {
    url: '/admin/vocation/',
  },
};
const URLGeneratorGet = {
  [pathname.PRODUCTS]: '/admin/product/',
  [pathname.TYPE]: '/product/type/',
  [pathname.USAGE]: '/product/usage/',
  [pathname.BRAND]: '/product/brand/',
  [pathname.ORDER]: '/admin/orders/',
  [pathname.FILTER]: '/product/extraFilter/',
  [pathname.JOB]: '/vocation/',
};

const URLGeneratorPutDel = {
  [pathname.TYPE]: '/admin/type/',
  [pathname.USAGE]: '/admin/usage/',
  [pathname.BRAND]: '/admin/brand/',
  [pathname.ORDER]: '/admin/orders/',
  [pathname.FILTER]: '/admin/extraFilter/',
  [pathname.JOB]: '/admin/vocation/',
};

export {
  URLGeneratorPost, URLGeneratorGet, URLGeneratorPutDel, URLGeneratorCreate,
};
