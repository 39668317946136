import { handleActions } from 'redux-actions';
import {
    sendPermissionsStatus,
    sendPermissionsActions,
    getAdministratorsStatus,
    getAdministratorsActions
} from './reducers';

const initialState = {
    sendPermissionsStatus,
    getAdministratorsStatus
};

export default handleActions(
    {
        ...sendPermissionsActions,
        ...getAdministratorsActions
    },
    initialState
);
