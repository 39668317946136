import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Avatar } from 'antd';
import TrashSvg from '../../../../svgs/TrashSvg';

function SlideItem({ item, setDelete }) {
  const handleDelete = useCallback(() => {
    setDelete(item);
  }, [setDelete, item]);

  const imageSrc = item.image && `${process.env.REACT_APP_HOST_NAME}/mainSlider/${item.image}`;
  const videoSrc = item.url && `${process.env.REACT_APP_HOST_NAME}/mainSlider/${item.url}`;


  return (
    <div className="slidesItem">
      {
        imageSrc && (
        <a href={imageSrc} target="_blank" rel="noopener noreferrer">
          <Avatar shape="square" size={64} src={imageSrc} />
        </a>
        )
      }
      {videoSrc && <a href={videoSrc} target="_blank" rel="noopener noreferrer">Հոկովակի հղում</a>}
      <button type="button" onClick={handleDelete} className="btnChangers" aria-label="trash"><TrashSvg /></button>
    </div>
  );
}

SlideItem.propTypes = {
  item: PropTypes.objectOf(PropTypes.any),
  setDelete: PropTypes.func.isRequired,
};
SlideItem.defaultProps = {
  item: {},
};

export default SlideItem;
