import Axios from '../../utils/Axios';

export const getSlidesRequest = () => Axios.request({
  method: 'get',
  url: '/product/mainSlider',
});

export const createSlideRequest = ({ data }) => Axios.request({
  method: 'post',
  url: '/admin/mainSlider',
  data,
});

export const deleteSlideRequest = ({ id }) => Axios.request({
  method: 'delete',
  url: `/admin/mainSlider/${id}`,
});
