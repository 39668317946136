import { useCallback, useState } from 'react';

export default function () {
  const [showCreate, setShowCreate] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [modalItem, setModalItem] = useState(null);

  const toggleCreate = useCallback(() => setShowCreate(!showCreate), [showCreate]);

  const setDelete = useCallback((item) => {
    setModalItem(item);
    setShowDelete(true);
  }, [setShowDelete, setModalItem]);

  const cancelChange = useCallback(() => {
    setShowDelete(false);
    setModalItem(null);
  }, [setShowDelete, setModalItem]);

  return {
    toggleCreate, showDelete, modalItem, setDelete, cancelChange, showCreate,
  };
}
