import { handleActions } from 'redux-actions';

import {
  searchSelect,
  searchSelectSuccess,
  searchSelectError,
  searchSelectReset,
} from './action';

const initialState = {
  types: {
    loading: false,
    error: false,
    list: [],
  },
  usages: {
    loading: false,
    error: false,
    list: [],
  },
  brand: {
    loading: false,
    error: false,
    list: [],
  },
  batteries: {
    loading: false,
    error: false,
    list: [],
  },
  paperBags: {
    loading: false,
    error: false,
    list: [],
  },
  extraFilter: {
    loading: false,
    error: false,
    list: [],
  },
};

export default handleActions({
  [searchSelect]: (state, { payload: { name } }) => ({
    ...state,
    [name]: {
      loading: true,
      list: [],
      error: false,
    },
  }),
  [searchSelectSuccess]: (state, { payload: { name, list } }) => ({
    ...state,
    [name]: {
      loading: false,
      list: [...list],
      error: false,
    },
  }),
  [searchSelectError]: (state, { payload: { name } }) => ({
    ...state,
    [name]: {
      loading: false,
      list: [],
      error: true,
    },
  }),
  [searchSelectReset]: (state, { payload: { name } }) => ({
    ...state,
    [name]: {
      ...initialState[name],
    },
  }),

}, initialState);
