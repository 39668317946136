import {
  handleActions,
} from 'redux-actions';
import {
  getTableData,
  getTableDataError,
  getTableDataSuccess,
  getTableDataReset,
  changeTable, setKey,
} from './action';


const initialState = {
  list: [],
  isFetching: false,
  error: false,
  page: 1,
  pages: 1,
  key: 'currentOrder',
};
export default handleActions({
  [getTableData]: (state) => ({
    ...state,
    isFetching: true,
  }),
  [getTableDataSuccess]: (state, {
    payload: {
      list,
      pages,
      page,
    },
  }) => ({
    ...state,
    isFetching: false,
    list,
    pages: pages || state.pages,
    page: page || state.page,
  }),
  [getTableDataError]: (state) => ({
    ...state,
    isFetching: false,
    error: true,
  }),
  [changeTable]: (state, { payload: { list } }) => ({
    ...state,
    list: [
      ...list,
    ],
  }),
  [getTableDataReset]: () => ({
    ...initialState,
  }),
  [setKey]: (state, { payload: { key } }) => ({
    ...state,
    key,
  }),
}, initialState);


export const selectList = ({ tables }) => tables.list;
export const selectPage = ({ tables }) => tables.page;
export const selectKey = ({ tables }) => tables.key;
export const selectPageOnDel = (ids) => ({ tables }) => {
  const { pages, list } = tables;
  let { page } = tables;
  if (page === pages && page !== 1 && ids.length === list.length) page -= 1;
  return page;
};
