import { handleActions } from 'redux-actions';
import {
  saveProductImages,
  saveProductImagesSuccess,
  saveProductImagesError,
  saveProductImagesReset,
  saveProductEdit,
  saveProductEditSuccess,
  saveProductEditError,
  saveProductEditReset,
  deleteProduct,
  deleteProductSuccess,
  deleteProductError,
  deleteProductReset,
} from './action';

const initialState = {

  changeImages: {
    loading: false,
    error: false,
    success: false,
  },
  edit: {
    loading: false,
    success: false,
    error: false,
  },
  delete: {
    loading: false,
    success: false,
    error: false,
  },
};
export default handleActions({
  [saveProductImages]: (state) => ({
    ...state,
    changeImages: {
      loading: true,
      error: false,
      success: false,
    },
  }),
  [saveProductImagesSuccess]: (state) => ({
    ...state,
    changeImages: {
      loading: false,
      error: false,
      success: true,
    },
  }),
  [saveProductImagesError]: (state) => ({
    ...state,
    changeImages: {
      loading: false,
      error: true,
      success: false,
    },
  }),
  [saveProductImagesReset]: () => ({ ...initialState }),
  [saveProductEdit]: (state) => ({
    ...state,
    edit: {
      loading: true,
      error: false,
      success: false,
    },
  }),
  [saveProductEditSuccess]: (state) => ({
    ...state,
    edit: {
      loading: false,
      error: false,
      success: true,
    },
  }),
  [saveProductEditError]: (state) => ({
    ...state,
    edit: {
      loading: false,
      error: true,
      success: false,
    },
  }),
  [saveProductEditReset]: (state) => ({
    ...state,
    edit: {
      loading: false,
      error: false,
      success: false,
    },
  }),
  [deleteProduct]: (state) => ({
    ...state,
    delete: {
      loading: true,
      success: false,
      error: false,
    },
  }),
  [deleteProductSuccess]: (state) => ({
    ...state,
    delete: {
      loading: false,
      success: true,
      error: false,
    },
  }),
  [deleteProductError]: (state) => ({
    ...state,
    delete: {
      loading: false,
      success: false,
      error: true,
    },
  }),
  [deleteProductReset]: (state) => ({
    ...state,
    delete: {
      ...initialState.delete,
    },
  }),
}, initialState);

export const selectChangesImagesSuccess = ({ editProduct: { changeImages } }) => changeImages.success;
export const selectChangesImagesLoading = ({ editProduct: { changeImages } }) => changeImages.loading;
