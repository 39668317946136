import io from 'socket.io-client';
import { message } from 'antd';
import store from '../store/configureStore';

const key = 'SOCKET_LOADER_KEY ';


export default (successAction, errorAction) => {
  const socket = io(process.env.REACT_APP_HOST_NAME);
  message.loading({ content: 'loading', key, duration: 0 });

  let timerId = null;
  const errorHandlers = (e) => {
    message.error({ content: 'Error', key, duration: 2 });
    store.dispatch(errorAction());
    socket.close();
    clearTimeout(timerId);
  };
  timerId = setTimeout(
    errorHandlers,
    10000,
  );
  socket.on('csv_completed', (data) => {
    const url = `${process.env.REACT_APP_HOST_NAME}/api/admin/download/${data.path}`;
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = data.path;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    message.success({ content: 'success', key });
    clearTimeout(timerId);
    socket.close();
    store.dispatch(successAction());
  });
  socket.on('csv_create_error', errorHandlers);
  socket.on('error', errorHandlers).on('connect_error', errorHandlers).on('connect_timeout', errorHandlers);
};
