import * as Yup from 'yup';

export const getSlideSchema = () => Yup.object().shape({
  image: Yup.array().when('video', {
    is: (video) => !video.length,
    then: Yup.array().required('Please upload an image or video'),
  }),
});

export const MAX_SLIDES = 5;
