import { createActions } from 'redux-actions';

export const {
  getWeekTime, getWeekTimeSuccess, getWeekTimeError,
  postWeekTime, postWeekTimeSuccess, postWeekTimeError,
  resetWeekTime,
} = createActions(
  'GET_WEEK_TIME', 'GET_WEEK_TIME_SUCCESS', 'GET_WEEK_TIME_ERROR',
  'POST_WEEK_TIME', 'POST_WEEK_TIME_SUCCESS', 'POST_WEEK_TIME_ERROR',
  'RESET_WEEK_TIME',
);
