import { handleActions } from 'redux-actions';
import {
  getProfileFetchSuccess,
  loginSuccess,
  loginError,
  login,
  logout,
} from './action';

const initialState = {
  initialFetching: false,
  authorization: { loading: false },
  info: {},
  isAuthenticated: false,
};

export default handleActions({
  [getProfileFetchSuccess]: (state) => ({ ...state, initialFetching: true }),
  [login]: (state) => ({
    ...state,
    authorization:
    { loading: true },

    isAuthenticated: false,
  }),
  [loginSuccess]: (state, { payload: { token } }) => ({
    ...state,
    authorization:
    { loading: false },
    isAuthenticated: true,
    initialFetching: true,
    info: { ...state.info, token },
  }),
  [loginError]: (state) => ({
    ...state,
    authorization:
    { loading: false },

    isAuthenticated: false,
  }),
  [logout]: () => ({
    ...initialState,
    initialFetching: true,
  }),
}, initialState);
