import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import './error.css';

export default function NoMatch() {
  return (
    <div>
      <div id="error-404" className="error-box">
        <ul>
          <li className="error-404">
            <div id="f404-1" className="f404">4</div>
            <div id="f404-2" className="f404">0</div>
            <div id="f404-3" className="f404">4</div>
          </li>
          <li className="error-text">
            <div>
              <h2>Oops!</h2>
              <p>Page not found.</p>
              <Button>
                <Link to="/">Take me home</Link>
              </Button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}
