import React, { memo } from 'react';
import { DatePicker } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';

const { RangePicker } = DatePicker;

function DownloadDatePicker({ values, setValues }) {
  const { from, to } = values;
  const defaultValue = from && to ? [moment(from), moment(to)] : null;
  return (
    <div style={{ marginBottom: '30px' }}>
      <RangePicker
        defaultValue={defaultValue}
        onChange={(_, dateArr) => {
          setValues({
            from: dateArr[0],
            to: dateArr[1],
          });
        }}
      />
    </div>
  );
}

DownloadDatePicker.propTypes = {
  values: PropTypes.objectOf(PropTypes.string).isRequired,
  setValues: PropTypes.func.isRequired,
};

export default memo(DownloadDatePicker);
