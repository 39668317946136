import { createActions } from 'redux-actions';

export const {
  getTableData,
  getTableDataSuccess,
  getTableDataError,
  getTableDataReset,
  changeTable,
  setKey,
} = createActions(
  'GET_TABLE_DATA',
  'GET_TABLE_DATA_SUCCESS',
  'GET_TABLE_DATA_ERROR',
  'GET_TABLE_DATA_RESET',
  'CHANGE_TABLE',
  'SET_KEY'
);
