import {
  takeEvery, call, all, put, select,
} from 'redux-saga/effects';
import { message } from 'antd';
import Axios from '../../utils/Axios';
import { selectDownloadStatus } from './reducer';
import {
  downloadCustomers,
  downloadCustomersError,
  downloadCustomersRequested,
  downloadCustomersSuccess,
} from './action';
import downloadSocket from '../../services/socket';

const downloadCustomersApi = () => Axios.request({
  method: 'post',
  url: '/admin/profile/print',
});

function* workDownloadCustomers() {
  const status = yield select(selectDownloadStatus);
  if (status.inProcess) return;
  try {
    yield put(downloadCustomersRequested());
    downloadSocket(downloadCustomersSuccess, downloadCustomersError);
    yield call(downloadCustomersApi);
  } catch ({ response: { data } }) {
    yield put(downloadCustomersError());
    message.error(data.message, 2);
  }
}
function* watchDownloadCustomers() {
  yield takeEvery(downloadCustomers, workDownloadCustomers);
}
export default function* () {
  yield all([watchDownloadCustomers()]);
}
