import { createActions } from 'redux-actions';

export const {
  editFetching,
  editFetchingSuccess,
  editFetchingError,
  editFetchingReset,
  editData,
  editDataSuccess,
  editDataError,
  createData,
  createDataSuccess,
  createDataError,
  deleteData,
  deleteDataSuccess,
  deleteDataError,
} = createActions(
  'EDIT_FETCHING',
  'EDIT_FETCHING_SUCCESS',
  'EDIT_FETCHING_ERROR',
  'EDIT_FETCHING_RESET',
  'EDIT_DATA',
  'EDIT_DATA_SUCCESS',
  'EDIT_DATA_ERROR',
  'CREATE_DATA',
  'CREATE_DATA_SUCCESS',
  'CREATE_DATA_ERROR',
  'DELETE_DATA',
  'DELETE_DATA_SUCCESS',
  'DELETE_DATA_ERROR',
);
