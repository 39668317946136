const reverseWeekdays = (week) => [1, 2, 3, 4, 5, 6, 7].filter((item) => !week.includes(item));

export const handleGetData = (data) => {
  const { time, week } = data;
  const reverseWeek = reverseWeekdays(week);
  return { time, week: reverseWeek };
};

export const handleSendData = (data) => {
  const { week, time } = data;
  const sortedTime = time.sort();
  const reverseWeek = reverseWeekdays(week);
  return { time: sortedTime, week: reverseWeek };
};
