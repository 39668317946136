import { createActions } from 'redux-actions';

export const {
    sendPermissions,
    sendPermissionsSuccess,
    sendPermissionsError,
    getAdministrators,
    getAdministratorsSuccess,
    getAdministratorsError,
    deleteAdministrator,
    deleteAdministratorSuccess,
    deleteAdministratorError,
    getAdministratorById,
    getAdministratorByIdSuccess,
    getAdministratorByIdError,
}
    = createActions(
    'SEND_PERMISSIONS',
    'SEND_PERMISSIONS_SUCCESS',
    'SEND_PERMISSIONS_ERROR',
    'GET_ADMINISTRATORS',
    'GET_ADMINISTRATORS_SUCCESS',
    'GET_ADMINISTRATORS_ERROR',
    'DELETE_ADMINISTRATOR',
    'DELETE_ADMINISTRATOR_SUCCESS',
    'DELETE_ADMINISTRATOR_ERROR',
    'GET_ADMINISTRATOR_BY_ID',
    'GET_ADMINISTRATOR_BY_ID_SUCCESS',
    'GET_ADMINISTRATOR_BY_ID_ERROR'
);
