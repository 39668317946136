import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import DownloadArchive from '../../../../pages/order/components/download-archive/DownloadArchive';
import paths from '../../../../constants';
import DownloadPromoModal from '../../../../pages/promoCode/components/download-promo-modal/DownloadPromoModal';
import DownloadFollowersModal
  from '../../../../pages/followers/components/download-followers-modal/DownloadFollowersModal';
import DownloadCustomers from '../../../../components/download-customers/DownloadCustomers';

function DownloadButton({ pathname }) {
  const orderKey = useSelector((state) => state.tables.key);
  if (pathname === `/${paths.ORDER}` && orderKey === 'archiveOrder') return <DownloadArchive />;
  if (pathname === `/${paths.PROMO_CODE}`) return <DownloadPromoModal />;
  if (pathname === `/${paths.FOLLOWERS}`) return <DownloadFollowersModal />;
  if (pathname === `/${paths.CUSTOMERS}`) return <DownloadCustomers />;
  return null;
}
DownloadButton.propTypes = {
  pathname: PropTypes.string.isRequired,
};

export default memo(DownloadButton);
