import { createActions } from 'redux-actions';

export const {
  getProfileFetchSuccess,
  getProfileFetch,
  loginSuccess,
  loginError,
  login,
  logout,
} = createActions(
  'GET_PROFILE_FETCH_SUCCESS',
  'GET_PROFILE_FETCH',
  'LOGIN_SUCCESS',
  'LOGIN_ERROR',
  'LOGIN',
  'LOGOUT',
);
