import { all } from 'redux-saga/effects';
import users from './users/saga';
import search from './search/saga';
import admin from './admin/saga';
import tables from './tables/saga';
import edit from './edit/saga';
import promoCodes from './promo-codes/saga';
import editProduct from './product-edit/saga';
import searchSelect from './search-select/saga';
import mainPage from './main-page/saga';
import order from './order/saga';
import orderDownload from './order-download/saga';
import followersDownload from './followers-download/saga';
import customersDownload from './customers-download/saga';
import deliveryTime from './delivery-time/saga';
import slides from './slides/saga'
import createProduct from './create-product/saga';
import administrators from './administrators/saga';


export default function* rootSaga() {
  yield all([
    users(),
    search(),
    admin(),
    tables(),
    edit(),
    editProduct(),
    promoCodes(),
    searchSelect(),
    mainPage(),
    order(),
    orderDownload(),
    followersDownload(),
    customersDownload(),
    deliveryTime(),
    slides(),
    createProduct(),
    administrators(),
  ]);
}
