import { handleActions } from 'redux-actions';
import {
  getWeekTime, getWeekTimeSuccess, getWeekTimeError,
  postWeekTime, postWeekTimeSuccess, postWeekTimeError,
  resetWeekTime,
} from './action';

const initialState = {
  data: null,
  loading: false,
  error: false,
  postStatus: {
    loading: false,
    error: false,
  },
};

export default handleActions({
  [getWeekTime]: (state) => ({
    ...state,
    loading: true,
    error: false,
  }),
  [getWeekTimeSuccess]: (state, { payload: { data } }) => ({
    ...state,
    loading: false,
    data,
  }),
  [getWeekTimeError]: (state) => ({
    ...state,
    loading: false,
    error: true,
  }),
  [postWeekTime]: (state) => ({
    ...state,
    postStatus: {
      loading: true,
      error: false,
    },
  }),
  [postWeekTimeSuccess]: (state) => ({
    ...state,
    postStatus: {
      loading: false,
      error: false,
    },
  }),
  [postWeekTimeError]: (state) => ({
    ...state,
    postStatus: {
      loading: false,
      error: true,
    },
  }),
  [resetWeekTime]: () => initialState,
}, initialState);
