import React, { useState, memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Button } from 'antd';
import DownloadDatePicker from '../../../../components/download-date-picker/DownloadDatePicker';
import { downloadFollowers } from '../../../../store/followers-download/action';

function DownloadFollowersModal() {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [pickerValues, setPickerValues] = useState({ from: null, to: null });

  const handleOk = useCallback(() => {
    const { from, to } = pickerValues;
    dispatch(downloadFollowers({ from, to }));
  }, [dispatch, pickerValues]);

  return (
    <div>
      <Button type="primary" className="marg" onClick={() => setShowModal(true)} icon="download">Ներբեռնել</Button>
      <Modal
        title="Download Followers"
        visible={showModal}
        destroyOnClose
        onCancel={() => setShowModal(false)}
        footer={[
          <Button key="back" onClick={() => setShowModal(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            disabled={!pickerValues.to}
            onClick={handleOk}
          >
          Download
          </Button>,
        ]}
      >
        <DownloadDatePicker values={pickerValues} setValues={setPickerValues} />
      </Modal>
    </div>
  );
}

export default memo(DownloadFollowersModal);
