import { handleActions } from 'redux-actions';
import {
  editFetching, editFetchingSuccess, editFetchingError, editFetchingReset,
  editData, editDataSuccess, editDataError,
} from './action';


const initialState = {
  isFetching: false,
  data: null,
  error: false,
  loading: false,
};

export default handleActions({
  [editData]: (state) => ({
    ...state,
    loading: true,
    error: false,
  }),
  [editDataSuccess]: (state) => ({
    ...state,
    loading: false,
    error: false,
  }),
  [editDataError]: (state) => ({
    ...state,
    loading: false,
    error: true,
  }),
  [editFetching]: (state) => ({
    ...state,
    isFetching: true,
  }),
  [editFetchingSuccess]: (state, { payload: { data } }) => ({
    ...state,
    isFetching: false,
    data,
  }),
  [editFetchingError]: (state) => ({
    ...state,
    data: null,
    isFetching: false,
    error: true,
  }),
  [editFetchingReset]: () => ({
    ...initialState,
  }),
}, initialState);


export const selectEditData = ({ edit }) => edit;
