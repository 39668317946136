import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { message, Upload } from 'antd';
import Button from 'antd/lib/button';
import Icon from 'antd/lib/icon';

function UploadVideo({ fileList, name, setFieldValue }) {
  const onRemove = useCallback(() => {
    setFieldValue(name, []);
  }, [setFieldValue, name]);

  const beforeUpload = useCallback((file) => {
    if (file.type !== 'video/mp4') {
      message.warning('File type must be video/mp4');
      return false;
    }
    if (file.size > 20 * 1024 * 1024) {
      message.warning('File is very large');
      return false;
    }

    setFieldValue(name, [file]);
    return false;
  }, [setFieldValue, name]);

  return (
    <div>
      <Upload
        fileList={fileList}
        onRemove={onRemove}
        beforeUpload={beforeUpload}
      >
        <Button>
          <Icon type="upload" />
           Վերբեռնել վիդեո
        </Button>
      </Upload>
    </div>
  );
}

UploadVideo.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  fileList: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default UploadVideo;
