const pathname = {
  FOLLOWERS: 'followers',
  PROMO_CODE: 'promoCode',
  PRODUCTS: 'products',
  CUSTOMERS: 'customers',
  MAIN_PAGE: 'mainPage',
  TYPE: 'type',
  AUTHENTICATION: 'authentication',
  USAGE: 'usage',
  BRAND: 'brand',
  ORDER: 'orders',
  FILTER: 'filter',
  JOB: 'job',
  DELIVERY_TIME: 'delivery-time',
  SUPPORT: 'support',
  MAIN_SLIDES: 'slides',
  ADMINISTRATORS: 'admin',
};

export const FORMAT = 'DD-MM-YYYY';

export const C_TOKEN = 'admtkn';

export default pathname;
