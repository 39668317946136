import { handleActions } from 'redux-actions';
import {
  saveDragResult,
  saveDragResultSuccess,
  saveDragResultError,
  saveDragResultReset,
  toggleSectionVisibility,
  toggleSectionVisibilitySuccess,
  toggleSectionVisibilityError,
  toggleSectionVisibilityReset,
} from './action';

const initialState = {
  drag: {
    loading: false,
    success: false,
    error: false,
  },
  switcher: {
    loading: false,
    success: false,
    error: false,
    loadingItems: {},
  },
};
export default handleActions({
  [saveDragResult]: (state) => ({
    ...state,
    drag: {
      loading: true,
      error: false,
      success: false,
    },
  }),
  [saveDragResultSuccess]: (state) => ({
    ...state,
    drag: {
      loading: false,
      error: false,
      success: true,
    },
  }),
  [saveDragResultError]: (state) => ({
    ...state,
    drag: {
      loading: false,
      error: true,
      success: false,
    },
  }),
  [saveDragResultReset]: (state) => ({
    ...state,
    drag: {
      ...initialState.drag,
    },
  }),
  [toggleSectionVisibility]: (state, { payload: { id } }) => ({
    ...state,
    switcher: {
      loadingItems: {
        ...state.switcher.loadingItems,
        [id]: true,
      },
      loading: true,
      error: false,
      success: false,
    },
  }),
  [toggleSectionVisibilitySuccess]: (state, { payload: { id } }) => ({
    ...state,
    switcher: {
      loadingItems: {
        ...state.switcher.loadingItems,
        [id]: false,
      },
      loading: false,
      error: false,
      success: true,
    },
  }),
  [toggleSectionVisibilityError]: (state, { payload: { id } }) => ({
    ...state,
    switcher: {

      loadingItems: {
        ...state.switcher.loadingItems,
        [id]: false,
      },
      loading: false,
      error: true,
      success: false,
    },
  }),
  [toggleSectionVisibilityReset]: (state) => ({
    ...state,
    switcher: {
      ...initialState.switcher,
    },
  }),
}, initialState);
