import { createActions } from 'redux-actions';

export const {
  downloadFollowers,
  downloadFollowersRequested,
  downloadFollowersSuccess,
  downloadFollowersError,
} = createActions(
  'DOWNLOAD_FOLLOWERS',
  'DOWNLOAD_FOLLOWERS_REQUESTED',
  'DOWNLOAD_FOLLOWERS_SUCCESS',
  'DOWNLOAD_FOLLOWERS_ERROR',
);
