import axios from 'axios';
import store from '../store/configureStore';
import { logout } from '../store/admin/action';
import { getCookie } from './cookies';
import { C_TOKEN } from '../constants';


const appAxios = axios.create({
  baseURL: `${process.env.REACT_APP_HOST_NAME}/api`,
});

appAxios.interceptors.request.use(
  (config) => {
    const newConfig = { ...config };
    newConfig.url = `${config.baseURL}${config.url}`;
    newConfig.headers.Authorization = `Bearer ${getCookie(C_TOKEN)}`;
    return newConfig;
  },
  (error) => Promise.reject(error),
);

appAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status } = error.response;

    if (status === 401) {
      store.dispatch(logout());
    }
    return Promise.reject(error);
  },
);

export default appAxios;
