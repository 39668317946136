import { handleActions } from 'redux-actions';
import {
  search, searchError, searchSuccess, searchReset,
} from './action';

const initialState = {
  loading: false,
  error: false,
  success: false,
  loadItems: {},
  searchWord: '',
};
export default handleActions({
  [search]: () => ({
    loading: true,
    error: false,
    success: false,
    searchWord: '',
  }),
  [searchSuccess]: (_, { payload: { searchWord } }) => ({
    loading: false,
    error: false,
    success: true,
    searchWord,
  }),
  [searchError]: () => ({
    loading: false,
    error: true,
    success: false,
    searchWord: '',
  }),
  [searchReset]: () => ({
    loading: false,
    error: false,
    success: false,
    searchWord: '',
  }),

}, initialState);


export const selectSearchWord = ({ search: { searchWord } }) => searchWord;
