import { handleActions } from 'redux-actions';
import {
  setDownloadSettings, downloadOrderError, downloadOrderRequested, downloadOrderSuccess,
} from './action';

const initialState = {
  downloadStatus: {
    inProcess: false,
    error: false,
  },
  downloadSettings: {
    orderInfo: ['deliveryAddress', 'phone', 'totalAmount'],
    orderedProdsInfo: [],
  },
};


export default handleActions({
  [setDownloadSettings]: (state, { payload: { orderInfo, orderedProdsInfo } }) => ({
    ...state,
    downloadSettings: {
      orderedProdsInfo,
      orderInfo,
    },
  }),
  [downloadOrderRequested]: (state) => ({
    ...state,
    downloadStatus: {
      inProcess: true,
      error: false,
    },
  }),
  [downloadOrderSuccess]: (state) => ({
    ...state,
    downloadStatus: {
      inProcess: false,
      error: false,
    },
  }),
  [downloadOrderError]: (state) => ({
    ...state,
    downloadStatus: {
      inProcess: false,
      error: true,
    },
  }),
}, initialState);

export const selectDownloadSettings = ({ orderDownload }) => orderDownload.downloadSettings;
export const selectDownloadStatus = ({ orderDownload }) => orderDownload.downloadStatus;
