import {
  all, takeLatest, put, call, select,
} from 'redux-saga/effects';
import {
  editFetching, editFetchingError, editFetchingSuccess,
  editData, editDataSuccess, editDataError,
  createData, createDataSuccess, createDataError,
  deleteData, deleteDataSuccess, deleteDataError,
} from './action';
import {
  getEditingDataQuery, putRequestByName, postRequestByName, deleteRequestByName,
} from './handleFunctions';
import pathname from '../../constants';
import { showMessage } from '../message/action';
import { getTableData } from '../tables/action';
import { selectPageOnDel, selectPage } from '../tables/reducer';


function* workEditFetching({ payload: { name, id } }) {
  try {
    const { data: { result } } = yield call(getEditingDataQuery, { name, id });
    let data = {};
    if (name === pathname.TYPE || name === pathname.FILTER) {
      result.langs.forEach((item) => {
        data[item.lang] = {
          name: item.name,
        };
        data.id = id;
      });
    } else data = result;
    yield put(editFetchingSuccess({ data }));
  } catch (error) {
    yield put(editFetchingError({ name }));
  }
}
function* watchEditFetching() {
  yield takeLatest(editFetching, workEditFetching);
}
function* workEditData({ payload: { name, data, id } }) {
  try {
    yield call(putRequestByName, { name, data, id });
    const page = yield select(selectPage);
    yield put(getTableData({ name, page }));
    yield put(editDataSuccess());
  } catch (e) {
    yield put(editDataError());
    yield put(showMessage({ status: 'error', text: e.response.data.message }));
  }
}
function* watchEditData() {
  yield takeLatest(editData, workEditData);
}

function* workCreateData({ payload: { name, data } }) {
  try {
    yield call(postRequestByName, { name, data });
    yield put(getTableData({ name }));
    yield put(createDataSuccess());
  } catch (e) {
    yield put(createDataError());
    yield put(showMessage({ status: 'error', text: e.response?.data?.message || 'No server response' }));
  }
}
function* watchCreateData() {
  yield takeLatest(createData, workCreateData);
}


function* workDeleteData({ payload: { name, ids } }) {
  try {
    yield call(deleteRequestByName, { name, ids });
    const page = yield select(selectPageOnDel(ids));
    yield put(getTableData({ name, page }));
    yield put(deleteDataSuccess());
  } catch (e) {
    yield put(deleteDataError());
    yield put(showMessage({ status: 'error', text: e.message }));
  }
}
function* watchDeleteData() {
  yield takeLatest(deleteData, workDeleteData);
}

export default function* () {
  yield all([
    watchEditFetching(),
    watchEditData(),
    watchCreateData(),
    watchDeleteData(),
  ]);
}
