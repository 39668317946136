import { createActions } from 'redux-actions';

export const {
  createPromoCodes,
  createPromoCodesSuccess,
  createPromoCodesError,
  deletePromoCodes,
  deletePromoCodesSuccess,
  deletePromoCodesError,
  editPromoCode,
  editPromoCodeSuccess,
  editPromoCodeError,
  downloadPromos,
  downloadPromosRequested,
  downloadPromosSuccess,
  downloadPromosError,
} = createActions(
  'CREATE_PROMO_CODES',
  'CREATE_PROMO_CODES_SUCCESS',
  'CREATE_PROMO_CODES_ERROR',
  'DELETE_PROMO_CODES',
  'DELETE_PROMO_CODES_SUCCESS',
  'DELETE_PROMO_CODES_ERROR',
  'EDIT_PROMO_CODE',
  'EDIT_PROMO_CODE_SUCCESS',
  'EDIT_PROMO_CODE_ERROR',
  'DOWNLOAD_PROMOS',
  'DOWNLOAD_PROMOS_REQUESTED',
  'DOWNLOAD_PROMOS_SUCCESS',
  'DOWNLOAD_PROMOS_ERROR',
);
