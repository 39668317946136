import {
  takeEvery, call, all, put, select,
} from 'redux-saga/effects';
import { message } from 'antd';
import Axios from '../../utils/Axios';
import { selectDownloadStatus } from './reducer';
import {
  downloadFollowers,
  downloadFollowersError,
  downloadFollowersRequested,
  downloadFollowersSuccess,
} from './action';
import downloadSocket from '../../services/socket';

const downloadFollowersApi = ({ from, to }) => Axios.request({
  method: 'post',
  url: '/admin/subscribers/print',
  data: {
    from,
    to,
  },
});

function* workDownloadFollowers({ payload: { from, to } }) {
  const status = yield select(selectDownloadStatus);
  if (status.inProcess) return;
  try {
    yield put(downloadFollowersRequested());
    downloadSocket(downloadFollowersSuccess, downloadFollowersError);
    yield call(downloadFollowersApi, { from, to });
  } catch ({ response: { data } }) {
    yield put(downloadFollowersError());
    message.error(data.message, 2);
  }
}
function* watchDownloadFollowers() {
  yield takeEvery(downloadFollowers, workDownloadFollowers);
}
export default function* () {
  yield all([watchDownloadFollowers()]);
}
