import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Col, Row } from 'antd';

function DownloadAttributes({ values, setValues }) {
  const { orderedProdsInfo, orderInfo } = values;
  return (
    <div>
      <Checkbox.Group
        style={{ width: '100%', marginTop: '25px' }}
        defaultValue={orderedProdsInfo}
        onChange={(val) => {
          setValues({
            ...values,
            orderedProdsInfo: val,
          });
        }}
      >
        <Row>
          <Col span={12}>
            <div className="leftCheckBox">
              <p style={{ display: 'inline-block' }}>Ապրանքի անունը</p>
              <Checkbox value="productName" />
            </div>
          </Col>
          <Col span={12}>
            <div className="rightCheckBox">
              <Checkbox value="count" />
              <p style={{ display: 'inline-block' }}>Ապրանքի քանակը</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <div className="leftCheckBox">
              <p style={{ display: 'inline-block' }}>Շտրիխ կոդ</p>
              <Checkbox value="xmlBarCode" />
            </div>
          </Col>
          <Col span={12}>
            <div className="rightCheckBox">
              <Checkbox value="price" />
              <p style={{ display: 'inline-block' }}>1 միավորի գինը</p>
            </div>
          </Col>
        </Row>
      </Checkbox.Group>
      <Checkbox.Group
        style={{ width: '100%' }}
        defaultValue={orderInfo}
        onChange={(val) => {
          setValues({
            ...values,
            orderInfo: val,
          });
        }}
      >
        <Row>
          <Col span={12}>
            <div className="leftCheckBox">
              <p style={{ display: 'inline-block' }}>Գումար (ներառյալ առաքում)</p>
              <Checkbox value="totalAmount" />
            </div>
          </Col>
          <Col span={12}>
            <div className="rightCheckBox">
              <Checkbox value="firstName" />
              <p style={{ display: 'inline-block' }}>Անուն ազգանուն</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <div className="leftCheckBox">
              <p style={{ display: 'inline-block' }}>Հեռ.</p>
              <Checkbox value="phone" />
            </div>
          </Col>
          <Col span={12}>
            <div className="rightCheckBox">
              <Checkbox value="email" />
              <p style={{ display: 'inline-block' }}>Էլ․հասցե</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <div className="leftCheckBox">
              <p style={{ display: 'inline-block' }}>Ընդհանուր գին</p>
              <Checkbox value="totalWithOutPromo" />
            </div>
          </Col>
          <Col span={12}>
            <div className="rightCheckBox">
              <Checkbox value="deliveryPrice" />
              <p style={{ display: 'inline-block' }}>Առաքման գումար</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <div className="leftCheckBox">
              <p style={{ display: 'inline-block' }}>Զեղչված գին</p>
              <Checkbox value="totalWithPromo" />
            </div>
          </Col>
          <Col span={12}>
            <div className="rightCheckBox">
              <Checkbox value="deliveryTime" />
              <p style={{ display: 'inline-block' }}>Առաքման ժամանակահատված</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <div className="leftCheckBox">
              <p style={{ display: 'inline-block' }}>Կուտակած բալեր</p>
              <Checkbox value="bonus" />
            </div>
          </Col>
          <Col span={12}>
            <div className="rightCheckBox">
              <Checkbox value="createdAt" />
              <p style={{ display: 'inline-block' }}>Պատվերի հաստատման ամսաթիվ</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <div className="leftCheckBox">
              <p style={{ display: 'inline-block' }}>Առաքման հասցե</p>
              <Checkbox value="deliveryAddress" />
            </div>
          </Col>
          <Col span={12}>
            <div className="rightCheckBox">
              <Checkbox value="paymentType" />
              <p style={{ display: 'inline-block' }}>Վճարման եղանակ</p>
            </div>
          </Col>
        </Row>
      </Checkbox.Group>
    </div>
  );
}

DownloadAttributes.propTypes = {
  values: PropTypes.objectOf(PropTypes.array).isRequired,
  setValues: PropTypes.func.isRequired,
};

export default memo(DownloadAttributes);
