import { getAdministratorById, getAdministratorByIdSuccess, getAdministratorByIdError } from '../../actions';

const getAdministratorByIdStatus = {
    getAdministratorByIdLoading: false,
    getAdministratorByIdSuccess: false,
    getAdministratorByIdError: null,
    getAdministratorByIdData: null,
};

const getAdministratorByIdActions = {
    [getAdministratorById]: state => ({
        ...state,
        getAdministratorByIdStatus: {
            ...state.getAdministratorByIdStatus,
            getAdministratorByIdLoading: true,
        },
    }),
    [getAdministratorByIdSuccess]: (state, { payload }) => ({
        ...state,
        getAdministratorByIdStatus: {
            ...state.getAdministratorByIdStatus,
            getAdministratorByIdLoading: false,
            getAdministratorByIdSuccess: true,
            getAdministratorByIdData: payload,
        },
    }),
    [getAdministratorByIdError]: (state, { payload: error }) => ({
        ...state,
        getAdministratorByIdStatus: {
            ...state.getAdministratorByIdStatus,
            getAdministratorByIdLoading: false,
            getAdministratorByIdError: error,
        },
    }),
};

export { getAdministratorByIdStatus, getAdministratorByIdActions };
