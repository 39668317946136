import React, { memo } from 'react';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import { downloadCustomers } from '../../store/customers-download/action';

function DownloadCustomers() {
  const dispatch = useDispatch();
  return (
    <Button type="primary" className="marg" onClick={() => dispatch(downloadCustomers())} icon="download">Ներբեռնել</Button>
  );
}

export default memo(DownloadCustomers);
