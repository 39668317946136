import React, { useState } from 'react';
import { Col } from 'antd';
import PropTypes from 'prop-types';
import HeaderTop from './header-top/HeaderTop';
import LeftSidebar from './left-sidebar/LeftSidebar';

const Layout = ({ children }) => {
  const [sidebarVisible, setSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setSidebarVisible((prevSidebarVisible) => !prevSidebarVisible);
  };

  return (
    <div>
    <Col span={4}>
      <LeftSidebar sidebarVisible={sidebarVisible} />
    </Col>
    <Col span={sidebarVisible ? 20 : 40}>
      <HeaderTop sidebarVisible={sidebarVisible} toggleSidebar={toggleSidebar} />
      {children}
    </Col>
  </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
