import {
  all, takeEvery, put, call,
} from 'redux-saga/effects';
import {
  saveDragResult,
  saveDragResultError,
  saveDragResultSuccess,
  toggleSectionVisibility,
  toggleSectionVisibilitySuccess,
  toggleSectionVisibilityError,
  saveDragResultReset,
} from './action';
import Axios from '../../utils/Axios';
// import { showMessage } from '../message/action';


function dragSectionsRequest({ orderSliders }) {
  return Axios.request({
    method: 'put',
    url: '/admin/slider',
    body: { orderSliders },
    data: { orderSliders },
  });
}

function* workSaveDragResult({ payload: { orders: orderSliders } }) {
  try {
    yield call(dragSectionsRequest, { orderSliders });
    yield put(saveDragResultSuccess());
  } catch (error) {
    yield put(saveDragResultError());
  } finally {
    yield put(saveDragResultReset());
  }
}
function* watchSaveDragResult() {
  yield takeEvery(saveDragResult, workSaveDragResult);
}

function toggleSectionsRequest({ orderSliders: cloneOrderList }) {
  return Axios.request({
    method: 'put',
    url: '/admin/slider',
    body: { orderSliders: cloneOrderList },
    data: { orderSliders: cloneOrderList },
  });
}


function* workToggleSectionVisibility({ payload: { id, status, orderedList } }) {
  const cloneOrderList = [...orderedList];
  cloneOrderList.find(({ id: itemId }) => itemId === id).status = status ? 'on' : 'off';
  try {
    yield call(toggleSectionsRequest, { orderSliders: cloneOrderList });
    yield put(toggleSectionVisibilitySuccess({ id }));
  } catch (error) {
    yield put(toggleSectionVisibilityError({ id }));
  }
}

function* watchToggleSectionVisibility() {
  yield takeEvery(toggleSectionVisibility, workToggleSectionVisibility);
}
export default function* () {
  yield all([
    watchSaveDragResult(),
    watchToggleSectionVisibility(),
  ]);
}
