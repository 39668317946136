import { handleActions } from 'redux-actions';
import { cleanMessage, showMessage } from './action';

const initialState = {
  status: null,
  text: null,
  timer: null,
  key: null,
};

export default handleActions({
  [showMessage]: (_, {
    payload: {
      text, timer, key, status,
    },
  }) => ({
    status: status || 'info',
    text,
    timer,
    key,
  }),
  [cleanMessage]: () => initialState,
}, initialState);
