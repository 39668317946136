import {
  URLGeneratorGet, URLGeneratorCreate, URLGeneratorPutDel,
} from '../utils/urlGenerator';
import Axios from '../../utils/Axios';


const getEditingDataQuery = ({ name, id }) => Axios.request({
  method: 'GET',
  url: URLGeneratorGet[name] + id,
});

const putRequestByName = ({ name, data, id }) => Axios.request({
  method: 'PUT',
  data,
  url: URLGeneratorPutDel[name] + id,
});
const postRequestByName = ({ name, data }) => Axios.request({
  method: 'POST',
  data,
  ...URLGeneratorCreate[name],
});
const deleteRequestByName = ({ name, ids }) => Axios.request({
  method: 'DELETE',
  data: {
    ids,
  },
  url: URLGeneratorPutDel[name],
});

export {
  getEditingDataQuery,
  putRequestByName,
  postRequestByName,
  deleteRequestByName,
};
