import { createActions } from 'redux-actions';

export const {
  searchSelect,
  searchSelectSuccess,
  searchSelectError,
  searchSelectReset,
} = createActions(
  'SEARCH_SELECT',
  'SEARCH_SELECT_SUCCESS',
  'SEARCH_SELECT_ERROR',
  'SEARCH_SELECT_RESET',
);
