import { createActions } from 'redux-actions';

export const {
  getSlides, getSlidesSuccess, getSlidesError,
  createSlide, createSlideSuccess, createSlideError,
  deleteSlide, deleteSlideSuccess, deleteSlideError,
} = createActions(
  'GET_SLIDES',
  'GET_SLIDES_SUCCESS',
  'GET_SLIDES_ERROR',
  'CREATE_SLIDE',
  'CREATE_SLIDE_SUCCESS',
  'CREATE_SLIDE_ERROR',
  'DELETE_SLIDE',
  'DELETE_SLIDE_SUCCESS',
  'DELETE_SLIDE_ERROR',
);
