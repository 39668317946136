import { createActions } from 'redux-actions';

export const {
  saveProductImages,
  saveProductImagesSuccess,
  saveProductImagesError,
  saveProductImagesReset,
  saveProductEdit,
  saveProductEditSuccess,
  saveProductEditError,
  saveProductEditReset,
  deleteProduct,
  deleteProductSuccess,
  deleteProductError,
  deleteProductReset,
} = createActions(
  'SAVE_PRODUCT_IMAGES',
  'SAVE_PRODUCT_IMAGES_SUCCESS',
  'SAVE_PRODUCT_IMAGES_ERROR',
  'SAVE_PRODUCT_IMAGES_RESET',
  'SAVE_PRODUCT_EDIT',
  'SAVE_PRODUCT_EDIT_SUCCESS',
  'SAVE_PRODUCT_EDIT_ERROR',
  'SAVE_PRODUCT_EDIT_RESET',
  'DELETE_PRODUCT',
  'DELETE_PRODUCT_SUCCESS',
  'DELETE_PRODUCT_ERROR',
  'DELETE_PRODUCT_RESET',
);
