import { message } from 'antd';


export default ({ setFieldValue, fieldName }) => {
  const handleUploadChange = (info) => {
    const isJpgOrPng = info.file.type === 'image/jpeg' || info.file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    } else {
      setFieldValue(fieldName, [...info.fileList.map(item => ({
        ...item,
        status: 'done',
      }))]);
    }
  };

  return { handleUploadChange };
};
