import constPathNames from '../../constants';
import Axios from '../../utils/Axios';
import { URLGeneratorPost } from '../utils/urlGenerator';


export const getNameByURLPath = (pathname) => {
  if (pathname.includes(constPathNames.FOLLOWERS)) return constPathNames.FOLLOWERS;
  if (pathname.includes(constPathNames.CUSTOMERS)) return constPathNames.CUSTOMERS;
  if (pathname.includes(constPathNames.PRODUCTS)) return constPathNames.PRODUCTS;
  if (pathname.includes(constPathNames.PROMO_CODE)) return constPathNames.PROMO_CODE;
  if (pathname.includes(constPathNames.MAIN_PAGE)) return constPathNames.MAIN_PAGE;
  if (pathname.includes(constPathNames.TYPE)) return constPathNames.TYPE;
  if (pathname.includes(constPathNames.USAGE)) return constPathNames.USAGE;
  if (pathname.includes(constPathNames.ORDER)) return constPathNames.ORDER;
  if (pathname.includes(constPathNames.BRAND)) return constPathNames.BRAND;
  if (pathname.includes(constPathNames.FILTER)) return constPathNames.FILTER;
  if (pathname.includes(constPathNames.JOB)) return constPathNames.JOB;
  return '';
};

const payments = {
  cash: 'Կանխիկ',
  'non-cash': 'Կրեդիտ քարտ',
  bonus: 'Բոնուսով',
};

export const handleData = (name, data) => {

  if (name === constPathNames.ORDER) {

    try {
      const dataSource = [];
      data.forEach((order) => {
        if(!order.userOrderedProds.length) return;

        const lastKey = `${order.id} ${order.userOrderedProds[order.userOrderedProds.length - 1].id}`;
        const currArr = [{
          ...order.userOrderedProds[0],
          orderId: order.id,
          userName: `${order.firstName} ${order.lastName}`,
          email: order.email,
          phone: order.phone,
          rowSpan: order.userOrderedProds.length,
          totalAmount: order.totalAmount,
          bonus: order.bonus,
          promoCode: order.promoCode || '-',
          totalWithOutPromo: order.totalWithOutPromo,
          totalWithPromo: order.promoCode ? order.totalWithPromo : '-',
          deliveryAddress: order.deliveryAddress,
          deliveryPrice: order.deliveryPrice,
          orderDate: new Date(order.createdAt).toLocaleString(),
          paymentType: payments[order.paymentType],
          deliveryTime: order.deliveryTime,
          key: `${order.id} ${order.userOrderedProds[0].id}`,
          lastKey,
          fullOrders: order,
          createData: order.createdAt
        }];
        order.createData = order.createdAt;
        for (let i = 1; i < order.userOrderedProds.length; i++) {
          currArr.push({
            ...order.userOrderedProds[i],
            rowSpan: 0,
            key: `${order.id} ${order.userOrderedProds[i].id}`,
            fullOrders: order
          });
        }
        const lastItem = currArr[currArr.length - 1];
        lastItem.deliveryAddress = order.deliveryAddress;
        lastItem.promoCode = order.promoCode || '-';
        lastItem.deliveryPrice = order.deliveryPrice;
        lastItem.deliveryTime = order.deliveryTime;
        lastItem.bonus = order.bonus;
        lastItem.orderDate = new Date(order.createdAt).toLocaleString();
        lastItem.paymentType = payments[order.paymentType];
        lastItem.email = order.email;
        lastItem.fullOrders = order;
        lastItem.createData = order.createdAt;


        dataSource.push(...currArr);
      });

      return dataSource;
    }catch (e) {
      console.log(e)
    }

  }
  data.forEach((row) => {
    row.key = row.id;
  });
  return data;
};

export const getTableDataQuery = ({ params, name, key }) => {
  if (name === constPathNames.ORDER) {
    params.status = key === 'archiveOrder' ? 'completed' : 'pending';
  }
  return Axios.request({
    data: params,
    ...URLGeneratorPost[name],
  });
};

export const getRowsFromData = (data) => {
  const { pages, result: { rows } } = data;
  return { rows, pages: pages || 1 };
};
