import React from 'react';
import {
  Input, Form, Button, Row, Col,
} from 'antd';
import useCreateSlide from './useCreateSlide';
import { ImageUpload } from '../../../../components';
import useUploadImage from './useUploadImage';
import UploadVideo from '../upload-video/UploadVideo';

function CreateSlides() {
  const { formik, loading } = useCreateSlide();
  const {
    values, handleChange, touched, errors, setFieldValue, handleSubmit,
  } = formik;
  const { handleUploadChange } = useUploadImage({ setFieldValue, fieldName: 'image' });
  return (
    <div className="create-edit-container">
      <h2>Ավելացնել շապիկ</h2>
      <Form onSubmit={handleSubmit}>
        <Row type="flex" gutter={15} className="adddSliderRow">
          <Col span={6}>
            <Form.Item>
              <ImageUpload
                onChange={handleUploadChange}
                listType="picture"
                name="imgList"
                fileList={values.image}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item>
              <UploadVideo fileList={values.video} setFieldValue={setFieldValue} name="video" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item>
              <Input name="link" placeholder="Սլայդերի հղում" value={values.link} onChange={handleChange} />
            </Form.Item>
          </Col>
          {touched.image && errors.image && <h3>{errors.image}</h3>}
          <Col span={6}>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                {loading ? 'Uploading' : 'Ավելացնել'}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default CreateSlides;
