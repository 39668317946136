import { handleActions } from 'redux-actions';
import { createProduct, createProductSuccess, createProductError } from './actions';

const initialState = {
    getProductsStatus: {
        error: false,
        loading: false,
        success: false,
    },
    data: [],
};

const getProductsLoadingStatus = () => ({
    error: false,
    success: false,
    loading: true,
});

const getProductsSuccessStatus = () => ({
    error: false,
    success: true,
    loading: false,
});

const getProductsErrorStatus = () => ({
    error: true,
    success: false,
    loading: false,
});

export default handleActions(
    {
        [createProduct]: state => ({
            ...state,
            getProductsStatus: getProductsLoadingStatus(),
        }),
        [createProductError]: state => ({
            ...state,
            getProductsStatus: getProductsErrorStatus(),
        }),
        [createProductSuccess]: (state, { payload }) => ({
            ...state,
            getProductsStatus: getProductsSuccessStatus(),
            data: payload,
        }),
    },
    initialState
);



