import { createActions } from 'redux-actions';

export const {
  getUsersOrders,
  getUsersOrdersSuccess,
  getUsersOrdersError,
} = createActions(
  'GET_USERS_ORDERS',
  'GET_USERS_ORDERS_SUCCESS',
  'GET_USERS_ORDERS_ERROR',
);
