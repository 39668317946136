import React, { useState, memo, useCallback } from 'react';
import { Modal, Button } from 'antd';
import { useDispatch } from 'react-redux';
import DownloadDatePicker from '../../../../components/download-date-picker/DownloadDatePicker';
import PromoRadioGroup from '../promo-radio-group/PromoRadioGroup';
import { downloadPromos } from '../../../../store/promo-codes/action';

function DownloadPromoModal() {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [pickerValues, setPickerValues] = useState({ from: null, to: null });
  const [isUsed, setIsUsed] = useState('');

  const handleOk = useCallback(() => {
    const { from, to } = pickerValues;
    dispatch(downloadPromos({ from, to, isUsed }));
    // setShowModal(false);
  }, [dispatch, pickerValues, isUsed]);

  return (
    <div>
      <Button type="primary" className="marg" icon="download" onClick={() => setShowModal(true)}>Ներբեռնել</Button>
      <Modal
        title="Ներբեռնել պրոմո կոդերը"
        visible={showModal}
        onCancel={() => setShowModal(false)}
        destroyOnClose
        footer={[
          <Button key="back" onClick={() => setShowModal(false)}>
            Չեղարկել
          </Button>,
          <Button
            key="submit"
            type="primary"
            disabled={!pickerValues.to}
            onClick={handleOk}
          >
            Ներբեռնել
          </Button>,
        ]}

      >
        <DownloadDatePicker values={pickerValues} setValues={setPickerValues} />
        <PromoRadioGroup value={isUsed} setValue={setIsUsed} />
      </Modal>
    </div>
  );
}

export default memo(DownloadPromoModal);
