import React from 'react';
import { Modal } from 'antd';


function MyModal(props) {
  const { children } = props;
  return (
    <Modal
      {...props}
    >
      {children}
    </Modal>
  );
}

MyModal.propTypes = {
  ...Modal.propTypes,
};

export default MyModal;
