import { handleActions } from 'redux-actions';
import {
  acceptOrder, deleteOrder, requestSuccess, requestError,
} from './action';

const initialState = {
  error: false,
  loading: false,
};

export default handleActions({
  [acceptOrder]: (state) => ({
    ...state,
    loading: true,
    error: false,
  }),
  [deleteOrder]: (state) => ({
    ...state,
    loading: true,
    error: false,
  }),
  [requestSuccess]: (state) => ({
    ...state,
    loading: false,
  }),
  [requestError]: (state) => ({
    ...state,
    loading: false,
    error: true,
  }),
}, initialState);
