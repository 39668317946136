import { combineReducers } from 'redux';
import users from './users/reducer';
import search from './search/reducer';
import admin from './admin/reducer';
import message from './message/reducer';
import tables from './tables/reducer';
import edit from './edit/reducer';
import promoCodes from './promo-codes/reducer';
import editProduct from './product-edit/reducer';
import searchSelect from './search-select/reducer';
import mainPage from './main-page/reducer';
import order from './order/reducer';
import orderDownload from './order-download/reducer';
import followersDownload from './followers-download/reducer';
import customersDownload from './customers-download/reducer';
import deliveryTime from './delivery-time/reducer';
import slides from './slides/reducer';
import createProduct from './create-product/reducer';
import administrators from './administrators/reducer';

export default combineReducers({
  users,
  search,
  admin,
  message,
  tables,
  mainPage,
  edit,
  editProduct,
  promoCodes,
  searchSelect,
  order,
  orderDownload,
  followersDownload,
  customersDownload,
  deliveryTime,
  slides,
  createProduct,
  administrators,
});
