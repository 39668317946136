import { handleActions } from 'redux-actions';
import { downloadFollowersRequested, downloadFollowersSuccess, downloadFollowersError } from './action';

const initialState = {
  downloadStatus: {
    inProcess: false,
    error: false,
  },
};

export default handleActions({
  [downloadFollowersRequested]: (state) => ({
    ...state,
    downloadStatus: {
      inProcess: true,
      error: false,
    },
  }),
  [downloadFollowersSuccess]: (state) => ({
    ...state,
    downloadStatus: {
      inProcess: false,
      error: false,
    },
  }),
  [downloadFollowersError]: (state) => ({
    ...state,
    downloadStatus: {
      inProcess: false,
      error: true,
    },
  }),
}, initialState);

export const selectDownloadStatus = ({ followersDownload }) => followersDownload.downloadStatus;
