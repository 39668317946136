import {
  all,
  takeEvery,
  takeLatest,
  select,
  call,
  put,
} from 'redux-saga/effects';
import {
  saveProductImages,
  saveProductImagesError,
  saveProductEdit,
  saveProductEditSuccess,
  saveProductEditError,
  saveProductEditReset,
  saveProductImagesSuccess,
  deleteProduct,
  deleteProductSuccess,
  deleteProductError,
  deleteProductReset,
} from './action';
import Axios from '../../utils/Axios';
import { selectEditData } from '../edit/reducer';
import { editFetchingSuccess, editFetchingReset } from '../edit/action';
import { showMessage, cleanMessage } from '../message/action';
import { getTableData } from '../tables/action';
import pathname from '../../constants';
import { selectPage } from '../tables/reducer';


function uploadProductImages({ file, id }) {
  return Axios.request({
    method: 'post',
    url: `/admin/product/savePhotos/${id}`,
    body: file,
    data: file,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}


function deleteProductImages({ deletedImages, id }) {
  return Axios.request({
    method: 'delete',
    url: `/admin/product/deletePhotos/${id}`,
    body: { delImages: deletedImages },
    data: { delImages: deletedImages },
  });
}

function* workSaveProductImages({ payload: { files, deletedImages, id } }) {
  try {
    const edit = yield select(selectEditData);
    const { data } = edit;
    const { photos } = data;
    const newData = { ...edit.data };

    if (deletedImages.length) {
      yield call(deleteProductImages, { deletedImages, id });
      const filtered = photos.filter((photo) => {
        if (deletedImages.indexOf(photo.id) === -1) {
          return true;
        } return false;
      });
      newData.photos = filtered;
    }

    if (files.length) {
      const file = new FormData();
      files.forEach((f) => {
        file.append('image', f.originFileObj);
      });
      file.set('id', id);

      const { data: { result } } = yield call(uploadProductImages, { file, id });
      newData.photos = result;
    }
    yield put(
      editFetchingSuccess({ data: newData }),
    );
    yield put(saveProductImagesSuccess());
  } catch (error) {
    yield put(saveProductImagesError());
  }
}

function* watchSaveProductImages() {
  yield takeLatest(saveProductImages, workSaveProductImages);
}


function saveProductEditRequest({ values, id }) {
  return Axios.request({
    method: 'put',
    url: `/admin/product/${id}`,
    body: { ...values },
    data: { ...values },
  });
}

function* workSaveProductEdit({ payload: { values, id } }) {
  yield put(showMessage({
    text: '...loading',
    status: 'loading',
    timer: 0,
    key: 'productKye',
  }));
  try {
    yield call(saveProductEditRequest, { values, id });
    yield put(saveProductEditSuccess());
    yield put(showMessage({
      text: 'product is successfully saved',
      status: 'success',
      key: 'productKye',
      timer: 3,
    }));
    yield put(editFetchingReset());
    const page = yield select(selectPage);
    yield put(getTableData({ name: pathname.PRODUCTS, page }));
  } catch (error) {
    yield (put(showMessage({
      text: 'product saving error',
      status: 'error',
      timer: 3,
      key: 'productKye',
    })));
    yield put(saveProductEditError());
  } finally {
    yield put(saveProductEditReset());
    yield put(cleanMessage());
  }
}
function* watchSaveProductEdit() {
  yield takeEvery(saveProductEdit, workSaveProductEdit);
}


function deleteProductsRequest({ ids }) {
  return Axios.request({
    method: 'delete',
    url: '/admin/product',
    data: { ids },
  });
}

function* workDeleteProduct({ payload: { id, ids } }) {
  try {
    yield put(showMessage({
      text: '...loading',
      status: 'loading',
      timer: 0,
      key: 'productKye',
    }));
    let newIds = [];
    if (id) {
      newIds = [id];
    } else if (ids) {
      newIds = [...ids];
    }
    yield call(deleteProductsRequest, { ids: newIds });
    yield put(deleteProductSuccess());
    const page = yield select(selectPage);
    yield put(getTableData({ name: pathname.PRODUCTS, page }));
    yield put(showMessage({
      text: 'success',
      status: 'success',
      timer: 2,
      key: 'productKye',
    }));
  } catch (error) {
    yield put(deleteProductError());
    yield put(showMessage({
      text: 'error',
      status: 'error',
      timer: 2,
      key: 'productKye',
    }));
  } finally {
    yield put(deleteProductReset());
  }
}
function* watchDeleteProduct() {
  yield takeEvery(deleteProduct, workDeleteProduct);
}

export default function* () {
  yield all([
    watchSaveProductImages(),
    watchSaveProductEdit(),
    watchDeleteProduct(),
  ]);
}
