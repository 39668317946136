import {
  handleActions,

} from 'redux-actions';
import {
  getUsersOrders,
  getUsersOrdersSuccess,
  getUsersOrdersError,
} from './action';

const initialState = {
  ordersLoading: {},
};
export default handleActions({
  [getUsersOrders]: (state, { payload: { id } }) => ({
    ...state,
    ordersLoading: { ...state.ordersLoading, [id]: true },
  }),
  [getUsersOrdersSuccess]: (state, { payload: { id } }) => ({
    ...state,
    ordersLoading: { ...state.ordersLoading, [id]: false },
  }),
  [getUsersOrdersError]: (state, { payload: { id } }) => ({
    ...state,
    ordersLoading: { ...state.ordersLoading, [id]: false },
  }),
}, initialState);
