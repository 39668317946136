import { createActions } from 'redux-actions';

export const {
  search,
  searchSuccess,
  searchError,
  searchReset,
} = createActions(
  'SEARCH',
  'SEARCH_SUCCESS',
  'SEARCH_ERROR',
  'SEARCH_RESET',
);
