import { createActions } from 'redux-actions';

export const {
  setDownloadSettings,
  downloadOrderByIds,
  downloadOrderByDate,
  downloadOrderRequested,
  downloadOrderSuccess,
  downloadOrderError,

} = createActions(
  'SET_DOWNLOAD_SETTINGS',
  'DOWNLOAD_ORDER_BY_IDS',
  'DOWNLOAD_ORDER_BY_DATE',
  'DOWNLOAD_ORDER_REQUESTED',
  'DOWNLOAD_ORDER_SUCCESS',
  'DOWNLOAD_ORDER_ERROR',
);
