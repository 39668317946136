import { createActions } from 'redux-actions';

export const {
  downloadCustomers,
  downloadCustomersRequested,
  downloadCustomersSuccess,
  downloadCustomersError,
} = createActions(
  'DOWNLOAD_CUSTOMERS',
  'DOWNLOAD_CUSTOMERS_REQUESTED',
  'DOWNLOAD_CUSTOMERS_SUCCESS',
  'DOWNLOAD_CUSTOMERS_ERROR',
);
