import {
  takeEvery, call, all, put, select,
} from 'redux-saga/effects';
import { message } from 'antd';
import downloadSocket from '../../services/socket';
import Axios from '../../utils/Axios';
import { selectDownloadSettings, selectDownloadStatus } from './reducer';
import {
  downloadOrderByIds, downloadOrderError,
  downloadOrderByDate, downloadOrderRequested, downloadOrderSuccess,
} from './action';

const downloadByIdsApi = ({ ids, settings }) => {
  const { orderedProdsInfo, orderInfo } = settings;
  orderedProdsInfo.sort();
  orderInfo.sort();
  const indexOfFirstName = orderInfo.indexOf('firstName');
  if (indexOfFirstName !== -1) {
    orderInfo.splice(indexOfFirstName + 1, 0, 'lastName');
  }
  Axios.request({
    method: 'post',
    data: {
      ids,
      orderedProdsInfo,
      orderInfo,
    },
    url: '/admin/orders/print',
  });
};
function* workDownloadOrderByIds({ payload: { ids } }) {
  const status = yield select(selectDownloadStatus);
  if (status.inProcess) return;
  try {
    yield put(downloadOrderRequested());
    const settings = yield select(selectDownloadSettings);
    downloadSocket(downloadOrderSuccess, downloadOrderError);
    yield call(downloadByIdsApi, { ids, settings });
  } catch ({ response: { data } }) {
    yield put(downloadOrderError());
    message.error(data.message, 2);
  }
}
function* watchDownloadOrderByIds() {
  yield takeEvery(downloadOrderByIds, workDownloadOrderByIds);
}

const downloadOrderByDateApi = ({
  from, to, orderInfo, orderedProdsInfo,
}) => {
  orderedProdsInfo.sort();
  orderInfo.sort();
  const indexOfFirstName = orderInfo.indexOf('firstName');
  if (indexOfFirstName !== -1) {
    orderInfo.splice(indexOfFirstName + 1, 0, 'lastName');
  }
  return Axios.request({
    method: 'post',
    url: '/admin/orders/print',
    data: {
      from,
      to,
      orderedProdsInfo,
      orderInfo,
      status: 'completed',
    },
  });
};

function* workDownloadOrderByDate({
  payload: {
    from, to, orderInfo, orderedProdsInfo,
  },
}) {
  const status = yield select(selectDownloadStatus);
  if (status.inProcess) return;
  try {
    yield put(downloadOrderRequested());
    downloadSocket(downloadOrderSuccess, downloadOrderError);
    yield call(downloadOrderByDateApi, {
      from, to, orderInfo, orderedProdsInfo,
    });
  } catch ({ response: { data } }) {
    yield put(downloadOrderError());
    message.error(data.message, 2);
  }
}
function* watchDownloadOrderByDate() {
  yield takeEvery(downloadOrderByDate, workDownloadOrderByDate);
}

export default function* () {
  yield all([
    watchDownloadOrderByIds(),
    watchDownloadOrderByDate(),
  ]);
}
