import React, { useState, memo } from 'react';
import { Modal, Button } from 'antd';
import { useDispatch } from 'react-redux';
import DownloadDatePicker from '../../../../components/download-date-picker/DownloadDatePicker';
import DownloadAttributes from '../download-attributes/DownloadAttributes';
import { downloadOrderByDate } from '../../../../store/order-download/action';


function DownloadArchive() {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [pickerValues, setPickerValues] = useState({
    from: null,
    to: null,
  });
  const [attributeValues, setAttributeValues] = useState({
    orderedProdsInfo: [],
    orderInfo: [],
  });
  const disabled = !(pickerValues.to && (attributeValues.orderInfo.length
    || attributeValues.orderedProdsInfo.length));

  return (
    <div>
      <Button type="primary" className="marg" onClick={() => setShowModal(true)} icon="download">Ներբեռնել</Button>
      <Modal
        visible={showModal}
        onCancel={() => setShowModal(false)}
        className="download-attributes"
        title="Download"
        footer={[
          <Button key="back" onClick={() => setShowModal(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            disabled={disabled}
            onClick={() => {
              const { from, to } = pickerValues;
              const { orderedProdsInfo, orderInfo } = attributeValues;
              dispatch(downloadOrderByDate({
                from, to, orderInfo, orderedProdsInfo,
              }));
            }}
            icon="download"
          >
            Download
          </Button>,
        ]}
        destroyOnClose
      >
        <DownloadDatePicker values={pickerValues} setValues={setPickerValues} />
        <DownloadAttributes values={attributeValues} setValues={setAttributeValues} />
      </Modal>
    </div>
  );
}


export default memo(DownloadArchive);
