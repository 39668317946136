import {
  all,
  takeEvery,
  put,
} from 'redux-saga/effects';
import {
  search, searchSuccess, searchError,
} from './action';
import { getNameByURLPath } from '../tables/handleFunctions';
import { getTableData } from '../tables/action';


function* workSearch({ payload: { pathname, value } }) {
  const name = getNameByURLPath(pathname);
  try {
    yield put(searchSuccess({ pathname, searchWord: value }));
    yield put(getTableData({ name }));
  } catch (error) {
    yield put(searchError({ pathname }));
  }
}

function* watchSearch() {
  yield takeEvery(search, workSearch);
}
export default function* () {
  yield all([
    watchSearch(),
  ]);
}
