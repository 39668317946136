// eslint-disable-next-line import/no-extraneous-dependencies
import {
  takeEvery,
  select,
  call,
  all,
  put,
} from '@redux-saga/core/effects';
import {
  getTableData,
  getTableDataSuccess,
  getTableDataError,
} from './action';
import { selectSearchWord } from '../search/reducer';
import { selectKey } from './reducer';
import { getTableDataQuery, getRowsFromData, handleData } from './handleFunctions';


function* workGetTableData({ payload: { name, page } }) {
  const searchWord = yield select(selectSearchWord);
  const key = yield select(selectKey);
  try {
    const res = yield call(getTableDataQuery, {
      name,
      params: {
        page: page || 1,
        limit: 10,
        searchWord: searchWord.trim() || '',
        offset: ((page - 1 || 0) * 10),
      },
      key,
    });

    const { rows, pages } = res ? getRowsFromData(res.data) : { rows: [] };

    yield put(getTableDataSuccess({
      list: handleData(name, rows),
      pages: pages || 1,
      page: page || 1,
    }));
  } catch (error) {
    yield put(getTableDataError());
  }
}
function* watchGetTableData() {
  yield takeEvery(getTableData, workGetTableData);
}

export default function* () {
  yield all([
    watchGetTableData(),
  ]);
}
