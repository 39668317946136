import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { selectSlidesData, selectSlidesError, selectSlidesLoading } from '../../store/slides/selectors';
import { getSlides } from '../../store/slides/action';

export default function () {
  const data = useSelector(selectSlidesData);
  const loading = useSelector(selectSlidesLoading);
  const error = useSelector(selectSlidesError);

  const dispatch = useDispatch();
  const getSlidesAction = useCallback(() => {
    dispatch(getSlides());
  }, [dispatch]);

  useEffect(() => {
    getSlidesAction();
  }, [getSlidesAction]);

  return { data, loading, error };
}
