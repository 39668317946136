import React from 'react';
import {
  Upload, Button, Icon,
} from 'antd';
import PropTypes from 'prop-types';


function ImageUpload(props) {
  const { fileList } = props;
  return (
    <Upload
      name="file"
      action="https://run.mocky.io/v3/596d5945-786c-499d-abb9-da5155a4b544"
      showUploadList={{ showDownloadIcon: false }}
      {...props}
    >
      {!fileList.length && (
      <Button>
        <Icon type="upload" />
         Վերբեռնել
      </Button>
      )}
    </Upload>
  );
}

ImageUpload.propTypes = {
  fileList: PropTypes.arrayOf(Array).isRequired,
};

export default ImageUpload;
