import React from 'react';
import { Table } from 'antd';

function MyTable(props) {
  return (
    <Table {...props} />
  );
}

function HardTable(props) {
  const { children } = props;
  return (
    <Table {...props}>{children}</Table>
  );
}
HardTable.propTypes = {
  ...Table.propTypes,
};

MyTable.propTypes = {
  ...Table.propTypes,
};

export default MyTable;

export { HardTable };
