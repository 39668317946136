import {
  takeEvery, call, all, put, select,
} from 'redux-saga/effects';
import { message } from 'antd';
import Axios from '../../utils/Axios';
import { getTableData } from '../tables/action';
import {
  createPromoCodes, createPromoCodesSuccess, createPromoCodesError,
  deletePromoCodes, deletePromoCodesError, deletePromoCodesSuccess,
  editPromoCode, editPromoCodeSuccess, editPromoCodeError,
  downloadPromos, downloadPromosError, downloadPromosSuccess,
  downloadPromosRequested,
} from './action';
import { cleanMessage, showMessage } from '../message/action';
import pathname from '../../constants';
import { selectPage, selectPageOnDel } from '../tables/reducer';
import { selectDownloadStatus } from './reducer';
import downloadSocket from '../../services/socket';


function createPromoCodesApi(params) {
  return Axios.request({
    method: 'post',
    url: '/admin/promoCode/create',
    data: params,
  });
}

function* workCreatePromoCodes({ payload: { count, validTill, percent } }) {
  try {
    yield call(createPromoCodesApi, { count, validTill, percent });
    yield put(createPromoCodesSuccess());
    yield put(getTableData({ name: pathname.PROMO_CODE }));
  } catch (e) {
    yield put(createPromoCodesError(e));
    yield put(showMessage({
      text: 'error',
      status: 'error',
      timer: 3.5,
    }));
    yield put(cleanMessage());
  }
}
function* watchCreatePromoCodes() {
  yield takeEvery(createPromoCodes, workCreatePromoCodes);
}


function deletePromoCodesApi(ids) {
  return Axios.request({
    method: 'delete',
    url: '/admin/promoCode',
    data: { ids },
  });
}
function* workDeletePromoCodes({ payload: { ids } }) {
  try {
    yield call(deletePromoCodesApi, ids);
    yield put(deletePromoCodesSuccess());
    const page = yield select(selectPageOnDel(ids));
    yield put(getTableData({ name: pathname.PROMO_CODE, page }));
  } catch ({ response }) {
    yield put(deletePromoCodesError());
    if (response?.data?.message.includes('using process')) {
      response.data.message = 'Պրոմոկոդը օգտագործման փուլում է';
    }
    yield put(showMessage({
      text: response?.data.message || 'Something went wrong',
      status: 'error',
      timer: 3.5,
    }));
  }
}
function* watchDeletePromoCodes() {
  yield takeEvery(deletePromoCodes, workDeletePromoCodes);
}


function editPromoCodeApi(params) {
  const {
    id, isUsed, validTill, percent,
  } = params;
  return Axios.request({
    method: 'put',
    url: `/admin/promoCode/${id}`,
    data: { isUsed, validTill, percent },
  });
}
function* workEditPromoCode({
  payload: {
    params,
  },
}) {
  try {
    yield call(editPromoCodeApi, params);
    yield put(editPromoCodeSuccess());
    const page = yield select(selectPage);
    yield put(getTableData({ name: pathname.PROMO_CODE, page }));
  } catch ({ response}) {
    yield put(editPromoCodeError(response?.data?.message));
    if (response?.data?.message.includes('using process')) {
      response.data.message = 'Պրոմոկոդը օգտագործման փուլում է';
    }
    yield put(showMessage({
      text: response?.data?.message || 'Something went wrong',
      status: 'error',
      timer: 3.5,
    }));
  }
}
function* watchEditPromoCode() {
  yield takeEvery(editPromoCode, workEditPromoCode);
}

const downloadPromosApi = ({ from, to, isUsed }) => Axios.request({
  method: 'post',
  url: '/admin/promoCode/print',
  data: {
    from,
    to,
    isUsed,
  },
});
function* workDownloadPromos({ payload: { from, to, isUsed } }) {
  const status = yield select(selectDownloadStatus);
  if (status.inProcess) return;
  try {
    yield put(downloadPromosRequested());
    downloadSocket(downloadPromosSuccess, downloadPromosError);
    yield call(downloadPromosApi, { from, to, isUsed });
  } catch ({ response: { data } }) {
    yield put(downloadPromosError());
    message.error(data.message, 2);
  }
}
function* watchDownloadPromos() {
  yield takeEvery(downloadPromos, workDownloadPromos);
}


export default function* () {
  yield all([
    watchCreatePromoCodes(),
    watchDeletePromoCodes(),
    watchEditPromoCode(),
    watchDownloadPromos(),
  ]);
}
