import { createActions } from 'redux-actions';

export const {
  acceptOrder,
  deleteOrder,
  requestSuccess,
  requestError,
} = createActions(
  'ACCEPT_ORDER',
  'DELETE_ORDER',
  'REQUEST_SUCCESS',
  'REQUEST_ERROR',
);
