import React from 'react';
import { Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import classes from './sidebar.module.less';
import '../../App.css';
import pathname from '../../constants';
import LogoSvg from '../../svgs/LogoSvg';


function LeftSidebar({ sidebarVisible }) {
  const { pathname: path } = useLocation();
  return (
    <div>
      {sidebarVisible ? (<div className={classes.sidebar} style={{ position: 'relative', zIndex: '2' }}>
        <Menu
          selectedKeys={[path]}
          theme="dark"
          mode="inline"
        >
          <Menu.ItemGroup
            className="logo"
            title={(
              <Link to="/">
                <h1>
                  MANKAN
                </h1>
              </Link>
        )}
          >

          </Menu.ItemGroup>
          <Menu.Item key={`/${pathname.ORDER}`}>
            <Link to={`/${pathname.ORDER}`}><span>Պատվերներ</span></Link>
          </Menu.Item>
          <Menu.Item key={`/${pathname.PRODUCTS}`}>
            <Link to={`/${pathname.PRODUCTS}`}><span>Ապրանքներ</span></Link>
          </Menu.Item>
          <Menu.Item key={`/${pathname.TYPE}`}>
            <Link to={`/${pathname.TYPE}`}><span>Տեսակ</span></Link>
          </Menu.Item>
          <Menu.Item key={`/${pathname.FILTER}`}>
            <Link to={`/${pathname.FILTER}`}><span>Հավելյալ ֆիլտր</span></Link>
          </Menu.Item>
          <Menu.Item key={`/${pathname.BRAND}`}>
            <Link to={`/${pathname.BRAND}`}><span>Բրենդ</span></Link>
          </Menu.Item>
          <Menu.Item key={`/${pathname.USAGE}`}>
            <Link to={`/${pathname.USAGE}`}><span>Օգտակարություն</span></Link>
          </Menu.Item>
          <Menu.Item key={`/${pathname.PROMO_CODE}`}>
            <Link to={`/${pathname.PROMO_CODE}`}><span>Պրոմո կոդ</span></Link>
          </Menu.Item>
          <Menu.Item key={`/${pathname.CUSTOMERS}`}>
            <Link to={`/${pathname.CUSTOMERS}`}><span>Հաճախորդներ</span></Link>
          </Menu.Item>
          <Menu.Item key={`/${pathname.FOLLOWERS}`}>
            <Link to={`/${pathname.FOLLOWERS}`}><span>Հետևորդներ</span></Link>
          </Menu.Item>
          <Menu.Item key={`/${pathname.MAIN_PAGE}`}>
            <Link to={`/${pathname.MAIN_PAGE}`}><span>Գլխավոր Էջ</span></Link>
          </Menu.Item>
          <Menu.Item key={`/${pathname.JOB}`}>
            <Link to={`/${pathname.JOB}`}><span>Աշխատանք</span></Link>
          </Menu.Item>
          <Menu.Item key={`/${pathname.DELIVERY_TIME}`}>
            <Link to={`/${pathname.DELIVERY_TIME}`}><span>Առաքման ժամանակահատված</span></Link>
          </Menu.Item>
          <Menu.Item key={`/${pathname.MAIN_SLIDES}`}>
            <Link to={`/${pathname.MAIN_SLIDES}`}><span>Շապիկներ</span></Link>
          </Menu.Item>
          {/* <Menu.Item key={`/${pathname.ADMINISTRATORS}`}>
            <Link to={`/${pathname.ADMINISTRATORS}`}><span>Ադմինիստրատորներ</span></Link>
          </Menu.Item> */}
          <Menu.Item key={`/${pathname.SUPPORT}`}>
            <Link to={`/${pathname.SUPPORT}`}><span>Տեխնիկական</span></Link>
          </Menu.Item>

        </Menu>
      </div> ) : null}
    </div>
  );
}

export default LeftSidebar;
