import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useMemo } from 'react';
import { useFormik } from 'formik';
import { selectConfirmLoading, selectConfirmError } from '../../../../store/slides/selectors';
import { createSlide } from '../../../../store/slides/action';
import { getSlideSchema } from '../../constants';

export default function () {
  const loading = useSelector(selectConfirmLoading);
  const error = useSelector(selectConfirmError);

  const dispatch = useDispatch();

  const handleSubmit = useCallback((values) => {
    dispatch(createSlide({ values }));
  }, [dispatch]);

  const validationSchema = useMemo(() => getSlideSchema(), []);
  const initialValues = useMemo(() => (
    {
      link: '',
      video: [],
      image: [],
    }
  ), []);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: ((values) => {
      if (loading) return;
      handleSubmit(values);
    }),
  });

  return { formik, error, loading };
}
